<!--应急救援工单详情-->
<template>
  <div class="alarmDetail" v-if="show0">
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
      <div class="card-content" style="width: 93%">
        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333;">
          <a-button  @click="show1=!show1" style="font-weight: bold; font-size: 19px; background-color: unset">求救呼救信息</a-button>
          <a-button class="monitor" type="primary" @click="monitoring">实时监控</a-button>
        </h3>
        <div v-show="show1">
          <a-form-model-item label="电梯救援码" prop="liftrescue">
            <a-input v-model.trim="alarmfiles.liftrescue" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="电梯位置" prop="adress">
            <a-input v-model.trim="alarmfiles.adress" placeholder=""></a-input>
            <a-icon type="orientation" class="after-btn table-refresh" @click=""></a-icon>
          </a-form-model-item>
          <a-form-model-item label="被困人数" prop="trappednum">
            <a-input v-model="alarmfiles.trappednum" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="报警类型" prop="alarmtype">
            <a-select id="alarmtype" placeholder="请选择" v-model="alarmfiles.alarmtype" key="alarmfiles.alarmtype">
              <a-select-option value="">请选择</a-select-option>
              <a-select-option value="0">困人</a-select-option>
              <a-select-option value="1">故障</a-select-option>
              <a-select-option value="2">人员伤亡</a-select-option>
              <a-select-option value="3">其他</a-select-option>
              <a-select-option value="4">投诉</a-select-option>
              <a-select-option value="5">咨询</a-select-option>
              <a-select-option value="6">打错电话</a-select-option>
              <a-select-option value="7">恶意骚扰</a-select-option>
              <a-select-option value="8">误报</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="呼救人" prop="alarmcaller">
            <a-input v-model="alarmfiles.alarmcaller" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="呼救人电话" prop="callertel" >
            <a-input v-model="alarmfiles.callertel" placeholder="" style="width: 95%"></a-input>
            <a-icon v-if="information[1]=='false'" type="phone" class="after-btn table-refresh" disabled="disabled" @click="phoneCall(alarmfiles.callertel,alarmfiles.alarmcaller,1)" style=" font-size: 25px; font-weight: bold;width:5%"></a-icon>
            <a-icon v-if="information[1]=='true'" type="phone" class="after-btn table-refresh" disabled="disabled" @click="phoneCall(alarmfiles.callertel,alarmfiles.alarmcaller,1)" style=" color:blue;font-size: 25px; font-weight: bold;width:5%"></a-icon>
          </a-form-model-item>
          <a-form-model-item label="电梯问题" prop="liftfault">
            <a-input v-model="alarmfiles.liftfault" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="接警时间" prop="calledtime">
            <a-date-picker
                v-model="alarmfiles.calledtime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    },
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"
                @change="onChange_1"/>
          </a-form-model-item>
        </div>
        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show2=!show2" style="font-weight: bold; font-size: 19px; background-color: unset">使用单位信息</a-button>
          <a-button class="message" type="primary" @click="messageagain(userdep)">重发短信</a-button>
        </h3>
        <div v-show="show2">
          <a-form-model class="form-in-threecolumns" :label-col="{span:12}" :wrapper-col="{span:12}">
            <a-form-model-item label="安全管理员" prop="safeperson">
              <a-input v-model="queryParams.safeperson" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安全管理员电话" prop="safephone">
                <a-input v-model="queryParams.safephone" placeholder="" style="width:95%" read-only="read-only"></a-input>
                <a-icon v-if="information[2]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.safephone,queryParams.safeperson,2)" style=" font-size: 25px; font-weight: bold; width:5%"></a-icon>
                <a-icon v-if="information[2]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.safephone,queryParams.safeperson,2)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="safeconn">
              <a-select id="safeconn" placeholder="请选择" v-model="alarmfiles.safeconn" :disabled="disabled" @change="connect(queryParams.safephone,queryParams.safeperson,alarmfiles.safeconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.safeperson&&queryParams.safephone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.safeperson&&queryParams.safephone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="使用单位" prop="userdepname">
              <a-input v-model="formDatas.userdepname" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="使用单位电话" prop="telphone">
              <a-input v-model="queryParams.telphone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[3]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.telphone,formDatas.userdepname,3)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[3]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.telphone,formDatas.userdepname,3)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="userdepconn">
              <a-select id="userdepconn" placeholder="请选择" v-model="alarmfiles.userdepconn" :disabled="disabled" @change="connect(queryParams.telphone,formDatas.userdepname,alarmfiles.userdepconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="formDatas.userdepname&&queryParams.telphone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="formDatas.userdepname&&queryParams.telphone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show3=!show3" style="font-weight: bold; font-size: 19px; background-color: unset">一级响应信息</a-button>
          <a-button class="message" type="primary" @click="messageagain(maintenancedep)">重发短信</a-button>
        </h3>
        <div v-show="show3">
          <a-form-model-item label="维保单位响应时间" prop="maintenancedeptime">
            <a-date-picker
                v-model="alarmfiles.maintenancedeptime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="维保单位响应耗时" prop="maintenancedepcontime">
            <a-input v-model="alarmfiles.maintenancedepcontime" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model class="form-in-threecolumns" :label-col="{span:14}" :wrapper-col="{span:10}">
            <a-form-model-item label="维保负责人一" prop="maintenanceperson1" >
              <a-input v-model="queryParams.maintenanceperson1" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保负责人一电话" prop="maintenancephone1">
              <a-input v-model="queryParams.maintenancephone1" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[4]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancephone1,queryParams.maintenanceperson1,4)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[4]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancephone1,queryParams.maintenanceperson1,4)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="maintenanceper1conn">
              <a-select id="maintenanceper1conn" placeholder="请选择" v-model="alarmfiles.maintenanceper1conn" :disabled="disabled" @change="connect(queryParams.maintenancephone1,queryParams.maintenanceperson1,alarmfiles.maintenanceper1conn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.maintenanceperson1&&queryParams.maintenancephone1" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.maintenanceperson1&&queryParams.maintenancephone1" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="维保负责人二" prop="maintenanceperson2">
              <a-input v-model="queryParams.maintenanceperson2" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保负责人二电话" prop="maintenancephone2">
              <a-input v-model="queryParams.maintenancephone2" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[5]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancephone2,queryParams.maintenanceperson2,5)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[5]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancephone2,queryParams.maintenanceperson2,5)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="telconn">
              <a-select id="maintenanceper2conn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.maintenanceper2conn" @change="connect(queryParams.maintenancephone2,queryParams.maintenanceperson2,alarmfiles.maintenanceper2conn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.maintenanceperson2&&queryParams.maintenancephone2" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.maintenanceperson2&&queryParams.maintenancephone2" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="维保区域经理" prop="areamanager">
              <a-input v-model="queryParams.areamanager" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保区域经理电话" prop="areamanagerphone">
              <a-input v-model="queryParams.areamanagerphone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[6]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.areamanagerphone,queryParams.areamanager,6)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[6]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.areamanagerphone,queryParams.areamanager,6)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="areamanagerconn">
              <a-select id="areamanagerconn" placeholder="请选择" v-model="alarmfiles.areamanagerconn" :disabled="disabled" @change="connect(queryParams.areamanagerphone,queryParams.areamanager,alarmfiles.areamanagerconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.areamanager&&queryParams.areamanagerphone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.areamanager&&queryParams.areamanagerphone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="维保单位经理" prop="depmanager">
              <a-input v-model="queryParams.depmanager" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保单位经理电话" prop="depmanagerphone">
              <a-input v-model="queryParams.depmanagerphone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[7]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.depmanagerphone,queryParams.depmanager,7)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[7]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.depmanagerphone,queryParams.depmanager,7)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="depmanagerconn">
              <a-select id="depmanagerconn" placeholder="请选择" v-model="alarmfiles.depmanagerconn" :disabled="disabled" @change="connect(queryParams.depmanagerphone,queryParams.depmanager,alarmfiles.depmanagerconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.depmanager&&queryParams.depmanagerphone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.depmanager&&queryParams.depmanagerphone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="维保单位" prop="maintenancedepname">
              <a-input v-model="formDatas.maintenancedepname" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保单位电话" prop="maintenancedephone">
              <a-input v-model="queryParams.maintenancedephone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[8]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancedephone,formDatas.maintenancedepname,8)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[8]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.maintenancedephone,formDatas.maintenancedepname,8)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况" prop="maintenancedepconn">
              <a-select id="maintenancedepconn" placeholder="请选择" v-model="alarmfiles.maintenancedepconn" :disabled="disabled" @change="connect(queryParams.maintenancedephone,formDatas.maintenancedepname,alarmfiles.maintenancedepconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="formDatas.maintenancedepname&&queryParams.maintenancedephone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="formDatas.maintenancedepname&&queryParams.maintenancedephone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show4=!show4" style="font-weight: bold; font-size: 19px; background-color: unset">二级响应信息</a-button>
          <a-button class="message" type="primary" @click="messageagain(rescuedep)">重发短信</a-button>
        </h3>
        <div v-show="show4">
          <a-form-model-item label="救援机构响应时间" prop="pristationtime">
            <a-date-picker
                v-model="alarmfiles.pristationtime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="救援机构响应耗时" prop="pristationcontime" style="">
            <a-input v-model="alarmfiles.pristationcontime" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model class="form-in-threecolumns" :label-col="{span:15}" :wrapper-col="{span:9}">
            <a-form-model-item label="驻站人一">
              <a-input v-model="queryParams.stationperson1" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="驻站人一电话">
              <a-input v-model="queryParams.stationphone1" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[9]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.stationphone1,queryParams.stationperson1,9)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[9]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.stationphone1,queryParams.stationperson1,9)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="stationperson1conn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.stationperson1conn" @change="connect(queryParams.stationphone1,queryParams.stationperson1,alarmfiles.stationperson1conn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.stationperson1&&queryParams.stationphone1" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.stationperson1&&queryParams.stationphone1" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="驻站人二">
              <a-input v-model="queryParams.stationperson2" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="驻站人二电话">
              <a-input v-model="queryParams.stationphone2" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[10]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.stationphone2,queryParams.stationperson2,10)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[10]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.stationphone2,queryParams.stationperson2,10)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="stationperson2conn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.stationperson2conn" @change="connect(queryParams.stationphone2,queryParams.stationperson2,alarmfiles.stationperson2conn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.stationperson2&&queryParams.stationphone2" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.stationperson2&&queryParams.stationphone2" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="二级救援负责人" prop="pristationperson">
              <a-input v-model="queryParams.pristationperson" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="二级救援负责人电话" prop="pristationperphone">
              <a-input v-model="queryParams.pristationperphone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[11]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.pristationperphone,queryParams.pristationperson,11)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[11]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.pristationperphone,queryParams.pristationperson,11)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="pristationpersonconn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.pristationpersonconn" @change="connect(queryParams.pristationperphone,queryParams.pristationperson,alarmfiles.pristationpersonconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.pristationperson&&queryParams.pristationperphone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.pristationperson&&queryParams.pristationperphone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="二级救援站" prop="pristation">
              <a-input v-model="queryParams.pristation" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="二级救援站电话" prop="pristationphone">
              <a-input v-model="queryParams.pristationphone" placeholder="" style="width:95%" read-only="read-only"></a-input>
              <a-icon v-if="information[12]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.pristationphone,queryParams.pristation,12)" style="font-size: 25px; font-weight: bold; width:5%"></a-icon>
              <a-icon v-if="information[12]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(queryParams.pristationphone,queryParams.pristation,12)" style="color:blue; font-size: 25px; font-weight: bold; width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="pristationconn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.pristationconn" @change="connect(queryParams.pristationphone,queryParams.pristation,alarmfiles.pristationconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="queryParams.pristation&&queryParams.pristationphone" :value="1">接通未派人</a-select-option>
                <a-select-option v-if="queryParams.pristation&&queryParams.pristationphone" :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show5=!show5" style="font-weight: bold; font-size: 19px; background-color: unset">三级响应信息</a-button>
          <a-button class="message" type="primary" @click="">重发短信</a-button>
        </h3>
        <div v-show="show5">
          <a-form-model class="form-in-threecolumns" :label-col="{span:12}" :wrapper-col="{span:12}">
            <a-form-model-item>
              <span style="font-size:30px;margin-left:45%;width:95%">119</span>
              <a-icon type="phone" class="after-btn table-refresh" @click="phoneCall(119,'火警电话')" style=" font-size: 25px; font-weight: bold; margin-top:2px;color:red;width:5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item>
              <span style="font-size:30px;margin-left:45%;width:95%">120</span>
              <a-icon type="phone" class="after-btn table-refresh" @click="phoneCall(120,'急救电话')" style=" font-size: 25px; font-weight: bold; margin-top:2px; width:5%; color:red"></a-icon>
            </a-form-model-item>
            <a-form-model-item>
              <span style="font-size:30px;margin-left:45%;width:95%">110</span>
              <a-icon type="phone" class="after-btn table-refresh" @click="phoneCall(110,'报警电话')" style=" font-size: 25px; font-weight: bold; margin-top:2px; width:5%; color:red"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="conn_119" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.conn_119" @change="connect(119,'火警电话',alarmfiles.conn_119)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option :value="1">接通未派人</a-select-option>
                <a-select-option :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="conn_120" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.conn_120" @change="connect(120,'急救电话',alarmfiles.conn_120)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option :value="1">接通未派人</a-select-option>
                <a-select-option :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="conn_110" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.conn_110" @change="connect(110,'报警电话',alarmfiles.conn_110)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option :value="1">接通未派人</a-select-option>
                <a-select-option :value="2">接通已派人</a-select-option>
                <a-select-option :value="3">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show6=!show6" style="font-weight: bold; font-size: 19px; background-color: unset">救援专家</a-button>
          <a-button class="message" type="primary" @click="sendmessage(alarmfiles.specialistphone)">重发短信</a-button>
        </h3>
        <div v-show="show6">
          <a-form-model-item label="救援专家响应时间" prop="rescuespecialisttime">
            <a-date-picker
                v-model="alarmfiles.rescuespecialisttime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="救援专家响应耗时" prop="rescuespecialistscontime" style="">
            <a-input v-model="alarmfiles.rescuespecialistscontime" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model class="form-in-threecolumns" :label-col="{span:12}" :wrapper-col="{span:12}">
            <a-form-model-item label="专家组" prop="panel">
              <a-select placeholder="请选择" v-model="alarmfiles.panel">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">专家组1</a-select-option>
                <a-select-option :value="1">曳引系统专家</a-select-option>
                <a-select-option :value="2">维保单位</a-select-option>
                <a-select-option :value="3">检验机构</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="专家" prop="specialist" v-model="alarmfiles.specialist">
              <a-select placeholder="请选择">
                <a-select-option value="">请选择</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="专家电话" prop="specialistphone">
              <a-form-model-item>
                <a-input  placeholder="" style="width:135px;margin-top: -100px" v-model="alarmfiles.specialistphone" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-icon v-if="information[13]=='false'" type="phone" class="after-btn table-refresh" @click="phoneCall(alarmfiles.specialistphone,alarmfiles.specialist,13)" style=" font-size: 25px; font-weight: bold; margin-top:2px; margin-left:88px"></a-icon>
                <a-icon v-if="information[13]=='true'" type="phone" class="after-btn table-refresh" @click="phoneCall(alarmfiles.specialistphone,alarmfiles.specialist,13)" style="color:blue; font-size: 25px; font-weight: bold; margin-top:2px; margin-left:88px"></a-icon>
              </a-form-model-item>
            </a-form-model-item>
            <a-form-model-item label="电话接通情况">
              <a-select id="rescuespecialistsconn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.rescuespecialistsconn" @change="connect(alarmfiles.specialistphone,alarmfiles.specialist,alarmfiles.rescuespecialistsconn)">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">未接通</a-select-option>
                <a-select-option v-if="alarmfiles.specialist&&alarmfiles.specialistphone" :value="1">接通未排人</a-select-option>
                <a-select-option v-if="alarmfiles.specialist&&alarmfiles.specialistphone" :value="1">接通已排人</a-select-option>
                <a-select-option :value="2">信息不正确</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-button type="primary" @click="sendmessage(alarmfiles.specialistphone)" style="margin-left: 120px; margin-top: 6px;">发送短信</a-button>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show7=!show7" style="font-weight: bold; font-size: 19px; background-color: unset">当前状态</a-button>
        </h3>
        <div v-show="show7">
          <a-form-model class="form-in-fourcolumns" :label-col="{span:18}" :wrapper-col="{span:14}">
            <a-form-model-item label="救援进展情况" prop="rescueprog" :label-col="{span:16}" :wrapper-col="{span:8}">
              <a-icon type="redo" class="after-btn table-refresh" @click="initSpan()" style="width: 5%"></a-icon>
            </a-form-model-item>
            <a-form-model-item label="救援总时间" prop="constime" :label-col="{span:14}" :wrapper-col="{span:10}">
              <a-input  type="text" v-model="constimes" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="预计到达时间" prop="prearrtime" :label-col="{span:16}" :wrapper-col="{span:8}">
              <a-input  type="text" v-model="alarmfiles.prearrtime" placeholder="单位为分钟"></a-input>
            </a-form-model-item>
            <a-form-model-item label="短信历史" prop="message" :label-col="{span:16}" :wrapper-col="{span:8}">
              <a-icon type="redo" class="after-btn table-refresh" @click="initSpan()"></a-icon>
            </a-form-model-item>
          </a-form-model>
          <a-form-model style="width: 100%">
            <a-form-model-item prop="rescueprog" style="width: 48%;">
              <textarea v-model="alarmfiles.rescueprog" style="width:300%;height:150px;margin-left: 35%" placeholder="救援进展情况"></textarea>
            </a-form-model-item>
            <a-form-model-item prop="message" style="width: 48%;">
              <textarea v-model="alarmfiles.message" style="width:300%;height:150px;margin-left: 36%" placeholder="短信历史"></textarea>
            </a-form-model-item>
          </a-form-model>
          <a-form-model-item label="派遣时间" prop="disptime">
            <a-date-picker
                v-model="alarmfiles.disptime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="派遣耗时" prop="dispconstime">
            <a-input type="text" v-model="alarmfiles.dispconstime" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="响应人员到达时间" prop="resperarrtime">
            <a-date-picker
                v-model="alarmfiles.resperarrtime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="响应人员到达耗时" prop="resperconstime">
            <a-input v-model="alarmfiles.resperconstime" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="解救成功时间" prop="savetime">
            <a-date-picker
                v-model="alarmfiles.savetime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <a-form-model-item label="救援耗时" prop="rescueconstime">
            <a-input v-model="alarmfiles.rescueconstime" read-only="read-only"></a-input>
          </a-form-model-item>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  style="font-weight: bold; font-size: 19px; background-color: unset">救援反馈</a-button>
        </h3>
        <div v-show="show8">
          <a-form-model-item label="电梯事件类型">
            <a-select id="lifteventype" placeholder="请选择" v-model="alarmfiles.lifteventype">
              <a-select-option value="">请选择</a-select-option>
              <a-select-option value="0">困人</a-select-option>
              <a-select-option value="1">非困人故障</a-select-option>
              <a-select-option value="2">人员伤亡</a-select-option>
              <a-select-option value="3">其他</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="被谁救出" prop="rescuedby">
            <a-input v-model="alarmfiles.rescuedby" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="故障类型" prop="faultype">
            <a-select style="width: 100%" placeholder="请选择"  @change="onReasonChange" v-model="alarmfiles.faultype">
              <a-select-option v-for="firstreson in firstreson" :key="firstreson.value">
                {{ firstreson.reson }}
              </a-select-option>
            </a-select>
            <a-select style="width: 100%" placeholder="请选择" v-model="alarmfiles.secondfaultype">
              <a-select-option v-for="secondreson in selcity" :key="secondreson">
                {{ secondreson }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="救援结果" prop="rescueres">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.rescueres" required>
              <a-radio :value="0">
                人已救出
              </a-radio>
              <a-radio :value="1">
                其他
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="故障描述" prop="faultdesc">
            <textarea v-model="alarmfiles.faultdesc" style="width:336px;height:100px" placeholder="请输入"></textarea>
          </a-form-model-item>
          <a-form-model-item label="解救人数" prop="rescuenum">
            <a-input v-model="alarmfiles.rescuenum" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="维修回访人" prop="maintainback">
            <a-input v-model="alarmfiles.maintainback" placeholder="" required></a-input>
          </a-form-model-item>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  style="font-weight: bold; font-size: 19px; background-color: unset">救援评价</a-button>
        </h3>
        <div v-show="show9">
          <a-form-model-item label="回访方式" prop="backtype">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.backtype" required>
              <a-radio :value="0">
                首次电话回访
              </a-radio>
              <a-radio :value="1">
                现场回访
              </a-radio>
              <a-radio :value="2">
                常规电话回访
              </a-radio>
              <a-radio :value="3">
                其他
                <!--              <a-icon type="phone" class="after-btn table-refresh" @click="" style=" font-size: 20px; font-weight: bold; margin-top:0px; margin-left:10px;"></a-icon>-->
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="维保人员到达时间" prop="arrtime">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.arrtime" required>
              <a-radio :value="0">
                30分钟以内
              </a-radio>
              <a-radio :value="1">
                超过30分钟
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="维保人员评价" prop="mainteva">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.mainteva" required>
              <a-radio :value="0">
                良好
              </a-radio>
              <a-radio :value="1">
                好
              </a-radio>
              <a-radio :value="2">
                一般
              </a-radio>
              <a-radio :value="3">
                差
              </a-radio>
              <a-radio :value="4">
                恶劣
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="维保公司响应评价" prop="miantcomeva">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.miantcomeva" required>
              <a-radio :value="0">
                良好
              </a-radio>
              <a-radio :value="1">
                好
              </a-radio>
              <a-radio :value="2">
                一般
              </a-radio>
              <a-radio :value="3">
                差
              </a-radio>
              <a-radio :value="4">
                恶劣
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="安全管理人员评价" prop="safeva">
            <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.safeva" required>
              <a-radio :value="0">
                良好
              </a-radio>
              <a-radio :value="1">
                好
              </a-radio>
              <a-radio :value="2">
                一般
              </a-radio>
              <a-radio :value="3">
                差
              </a-radio>
              <a-radio :value="4">
                恶劣
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="救援情况说明" prop="rescuexplain">
            <a-textarea v-model="alarmfiles.rescuexplain" placeholder="请输入" required></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="救援回访人" prop="rescueback">
            <a-input v-model="alarmfiles.rescueback" placeholder="" required></a-input>
          </a-form-model-item>

        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show10=!show10" style="font-weight: bold; font-size: 19px; background-color: unset">录音历史</a-button>
        </h3>
        <div v-show="show10">
          <a-form-model style="text-align: center">
            <a-button type="primary" @click="getRecords">查看录音</a-button>
          </a-form-model>
        </div>

        <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
          <a-button  @click="show11=!show11" style="font-weight: bold; font-size: 19px; background-color: unset">图片和视频</a-button>
        </h3>
        <div v-show="show11" style="margin-left: 3%">
          <a-button @click="appOrPlat(true)" :class="buttonIndex == 1?'bgcolor':''">平台</a-button>
          <a-button @click="appOrPlat(false)" :class="buttonIndex == 2?'bgcolor':''">APP</a-button>
          <div v-if="pc==true">
            <h3>图片:</h3>
            <a-form-model>
              <img :src="picUrl1" v-if="picVisible" style="width: 500px;height: auto">
              <img :src="picUrl2" v-if="picVisible" style="width: 500px;height: auto">
              <img :src="picUrl3" v-if="picVisible" style="width: 500px;height: auto">
            </a-form-model>
            <h3>视频:</h3>
            <a-form-model>
            </a-form-model>
          </div>
          <div v-if="pc==false">
            <a-form-model style="overflow: hidden">
              <a-form-model-item>
                <h3>签到图片:</h3>
                <img :src="img1" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
              </a-form-model-item>
              <a-form-model-item>
                <h3>现场图片:</h3>
                <img :src="img2" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
              </a-form-model-item>
            </a-form-model>
            <a-form-model>
              <a-form-model-item>
                <h3>现场视频:</h3>
                <video :src="video" autoplay controls v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px;margin-left: 10px"></video>
              </a-form-model-item>
              <a-form-model-item>
                <h3>签名图片:</h3>
                <img :src="img3" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>

        <div class="save" style="text-align: center">
          <a-button v-if="this.signforflag" type="primary" @click="signFor">签收</a-button>
          <a-button v-if="this.arriveflag" type="primary" @click="arrive">响应人员抵达现场</a-button>
          <a-button v-if="this.rescuendflag" type="primary" @click="rescuend">救援结束</a-button>
          <a-button v-if="this.stopflag" type="primary" @click="stop">自行脱困</a-button>
          <a-button v-if="this.orderendflag" type="primary" @click="orderend">工单结束</a-button>
          <a-button v-if="this.saveflag" type="primary" @click="save">保存</a-button>
          <a-button type="primary" @click="close">关闭</a-button>
        </div>
      </div>
    </a-form-model>
    <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
    <alarmVideo v-if="videoVisible" :visible.sync="videoVisible" ref="alarmVideo" :VideoDevice="videoDevice"></alarmVideo>
  </div>
</template>
<script>
import pagination from "@/mixins/pagination";
import moment from "moment";
import {
  getLiftinfoById,
  getLiftinfoListByCondition,
} from "A/jcgn";
import {mapState} from "vuex";
import {
  addAlarmFiles, addEventrecord, findRescuepicByAlarmId,
  getAlarmFilesListByCondition,
  modifyAlarmFiles,
  sendMessage,
  viewUserImg
} from "A/thjlcx";
import {getEventrecordListByCondition} from "A/wlw";
import alarmMap from "V/yjcz/emergency/alarmMap";
import alarmVideo from "V/yjcz/emergency/alarmVideo";
import {getCache} from "U/index";
import "A/jquery-1.7.1.min.js"
import "A/agentuc.js"
import alarm from "V/yjcz/emergency/alarm";
import Bus from "V/yjcz/emergency/bus";
import checkTraceBack from "V/yjcz/emergency/checkTraceBack";
import {findParamsListByConditions, getMentenacegroupById} from "A/xtpz";
export default {
  name: "alarmDetail",
  mixins: [pagination],
  props:{
    PhoneData:{
      default: null
    },
    AlarmId:{
      default: null
    }
  },
  components: {
    alarmMap,
    alarmVideo,
    alarm,
    checkTraceBack,
  },
  data() {
    return {
      moment,
      show0: true,
      show1: true,
      show2: true,
      show3: true,
      show4: false,
      show5: false,
      show6: false,
      show7: true,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      pages: '',
      picVisible:false,
      imgVisible:false,
      phoneVisible:true,
      videoVisible:false,
      username:'',
      data:[],
      disabled:false,
      videoDevice:'',
      ringVisible:false,
      information:['false','false','false','false','false','false','false','false','false','false','false','false','false'],
      index:0,

      //图片
      baseUrl:"https://esp.u-lake.com/",
      picUrl1:'',
      picUrl2:'',
      picUrl3:'',
      fileField:'',
      urlList:[],
      pc:true,
      buttonIndex:1,
      imgLists:[],
      locaImgLists:[],
      img1:'',
      img2:'',
      img3:'',
      video:'',

      //工单状态
      signforflag:true,
      arriveflag:false,
      rescuendflag:false,
      stopflag:true,
      orderendflag:false,
      saveflag:true,

      //短信
      signName:"优湖梯视",
      sendRescuer:"SMS_219617001",
      sendTrapped:"SMS_219607135",
      sendArrived:"SMS_219607136",
      sendRescuend:"SMS_219627025",
      sendOrderend:"SMS_219627026",
      userdep:"userdep",
      maintenancedep:"maintenancedep",
      rescuedep:"rescuedep",

      //录音
      show:'',
      playtape:'',
      gettape:'',
      tapeUrl:'',
      checkTraceVisible:false,
      detaildata:'',

      //通话
      opcall: '',
      msg_div: '',
      user: '',
      password: '',
      login_ext_no: '',
      login_queue: '',
      stat_div: '',
      dia_num: '',
      caller:'',
      callers:[],

      //救援总耗时
      constimes:'',

      firstreson: [{value:'',reson:'请选择'},{value:0,reson:'人为原因'},{value:1,reson:'外部原因'},{value:2,reson:'门系统'},{value:3,reson:'曳引系统'},
        {value:4,reson:'导向系统'},{value:5,reson:'轿厢'},{value:6,reson:'控制系统'},{value:7,reson:'电气系统'},{value:8,reson:'安全保护装置'}],
      secondreson: [
        ['生活垃圾导致开关门受阻，电梯停止运行', '人的鲁莽行为（扒门、踹门、野蛮搬运等），恶意破坏，电梯停止运行', '装修垃圾导致开关门受阻，电梯停止运行','超载','阻挡关门时间过长，电梯无法运行','其他'],
        ['电梯在运行过程中出现的停电', '电气部件外部故障（进水、鼠咬、受潮、绝缘不良等）', '机房温度过高，电气控制系统自动保护','故障后自动恢复运行','其他'],
        ['轿门锁（机械或电气装置）失效','厅门锁（机械或电气装置）失效','门机（门电机、传动机构、驱动与控制系统等）故障','门刀与滚轮（球）间距调整不良','安全触板、光幕等防夹人保护装置无效','主动门与从动门之间的联动失效','门触点失效','门挂轮破损','门导向系统失效','其他'],
        ['平衡系数不在标准范围内','曳引轮（轴）磨损','曳引机缺油或油量过多','传动皮带过松','钢丝绳卡阻或跳槽','钢丝绳磨损','变速箱故障','其他'],
        ['导靴磨损超出标准范围','导轨润滑欠缺','其他'],
        ['轿厢壁变形','照明失效','紧急报警失效','其他'],
        ['控制主板失效','电气元器件（接触器、继电器、驱动器件等）失效 ','平层感应器失效','极限开关、急停开关、维修开关等传感器失效','电气连接不可靠','编码器失效','主电源开关失效','召唤失效','电梯重启故障','其他'],
        ['变频器失效','电动机失效','制动器故障','主电源断开','其他'],
        ['安全回路断开','消防开关失效','限速器开关动作','安全钳开关动作','缓冲器开关动作','张紧轮开关动作','上行超速保护开关动作','其他'],
      ],
      //根据第一个选项 获取第二给下拉框的数据
      selcity:[],
      //电梯事件类型
      lifteventypeList:[{id:0,title:"人员伤亡"},{id:1,title:"困人"},{id:2,title:"非困人故障"},{id:3,title:"其他"}],
      alarmfiles:{
        devicenum:'',
        alarmid:'',
        eventrecordid:'',
        trappednum:'',
        liftrescue:'',
        adress:'',
        alarmtype:'',
        alarmcaller:'',
        callertel:'',
        telconn:'',
        liftfault:'',
        calledtime:'',
        maintenancedeptime:'',
        maintenancedepcontime:'',
        pristationtime:'',
        pristationcontime:'',
        rescuespecialisttime:'',
        rescuespecialistscontime:'',
        rescueprog:'',
        constime:'',
        prearrtime:'',
        message:'',
        disptime:'',
        dispconstime:'',
        resperarrtime:'',
        resperconstime:'',
        savetime:'',
        rescueconstime:'',
        lifteventype:'',
        faultype:'',
        secondfaultype:'',
        faultdesc:'',
        rescuedby:'',
        rescueres:'',
        rescuenum:'',
        backtype:'',
        arrtime:'',
        mainteva:'',
        miantcomeva:'',
        safeva:'',
        rescuexplain:'',
        safeconn:'',
        userdepconn:'',
        maintenanceper1conn:'',
        maintenanceper2conn:'',
        areamanagerconn:'',
        depmanagerconn:'',
        maintenancedepconn:'',
        stationperson1conn:'',
        stationperson2conn:'',
        pristationpersonconn:'',
        pristationconn:'',
        conn_119:'',
        conn_120:'',
        conn_110:'',
        rescuespecialistsconn:'',
        panel:'',
        specialist:'',
        specialistphone:'',
        cancel:0,
        endflag:'0',
        ordernum:'',
        flowstep: '',
        rescueperson:'',
        maintainback:'',
        rescueback:'',
        taketime:'',
        location:'',
        waitorder:'',
        maintenancegroupid:'',
        endding:'',
        eventcode:'',
      },
      queryParams: {
        userdepid:'',
        maintenancedepid:'',
        safeperson:'',
        safephone:'',
        userdepname:'',
        telphone:'',
        maintenanceperson1:'',
        maintenancephone1:'',
        maintenanceperson2:'',
        maintenancephone2:'',
        areamanager:'',
        areamanagerphone:'',
        depmanager:'',
        depmanagerphone:'',
        stationperson1:'',
        stationphone1:'',
        stationperson2:'',
        stationphone2:'',
        pristationperson:'',
        pristationperphone:'',
        pristation:'',
        pristationphone:'',
        maintenancedephone:'',
        maintenancegroupid:'',
      },
      formDatas: {
        liftid:'',
        liftnum: '',
        liftrescue: '',
        traceabilitynum:'',
        adress: '',
        userdepid:'',
        userdepname:'',
        maintenancedepname:'',
      },
      formRules: {
        lifteventype:[{required: true, message: '请选择电梯事件类型' ,trigger:'blur,change'}],
        faultype: [{required: true, message: '请选择故障类型' ,trigger:'blur,change'}],
        rescueres: [{required: true, message: '请选择救援结果',trigger:'blur,change'}],
        backtype: [{required: true, message: '请选择回访方式',trigger:'blur,change'}],
        arrtime: [{required: true, message: '请选择维保人员到达时间',trigger:'blur,change'}],
        mainteva: [{required: true, message: '请评价维保人员',trigger:'blur,change'}],
        miantcomeva: [{required: true, message: '请评价维保公司',trigger:'blur,change'}],
        safeva: [{required: true, message: '请评价安全管理人员',trigger:'blur,change'}],
        rescuexplain: [{required: true, message: '请说明救援情况',trigger:'blur,change'}],
      },
      //拨号时间
      phoneCallTime:'',
    }
  },
  created() {
    this.initSpan();
    this.initData();
  },
  beforeDestroy () { // 在组件销毁之前清除自定义事件
    Bus.$off('caller-value');
    this.AlarmId='';
    this.alarmfiles.alarmid='';
  },
  computed:{
    ...mapState({
      unitId: state => state.main.unitId,
      codeRescue: state => state.main.codeRescue,
      eventRecordId: state => state.main.eventRecordId,
    }),
    second(){
      return  this.secondreson[0][0];
    }
  },
  watch:{
    'show11':function (){
      if(this.show11==true){
        this.getPic()
      }
    },
  },
  methods:{

    // handleOk(e) {
    //   this.ringVisible = false;
    //   this.show0=false
    //   this.pages = 'alarm'
    //   this.$emit('alarm')
    // },
    // alarmModal(){
    //   var that=this;
    //   // this.opcall=this.PhoneData
    //   callCenter.methods.bind_event(this.opcall)
    //   this.opcall.lib.event("ring").unsubscribe().subscribe(function (ext) {
    //     that.information[that.index]='false';
    //     if(ext.agent_state=='Receiving'){
    //       that.ringVisible=true;
    //       that.caller=ext.peer;
    //       setTimeout(()=> {
    //         Bus.$emit('caller-value',ext.peer)
    //       },2500)
    //       that.show0=false
    //       that.pages = 'alarm'
    //       that.$emit('alarm')
    //     }
    //     $("#dia").attr("disabled", true);
    //     $("#dia_num").attr("disabled", true);
    //     $("#hangup").attr("disabled", false);
    //     $("#answer").attr("disabled", false);
    //     $("#spy").attr("disabled", true);
    //     $("#breakin").attr("disabled", true);
    //     $("#breakin2threeway").attr("disabled", true);
    //     $("#blind_transfer").attr("disabled", false);
    //     console.log("电话振铃了[此处完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
    //   });
    // },
    // //坐席
    // bind_event(opcall) {
    //   var that =this;
    //   opcall.lib.event("login").unsubscribe().subscribe(function () {
    //     // console.log("坐席登陆成功，分机号：" + opcall.cfg.ext_no);
    //     // console.log(this.opcall.cfg);
    //     // this.stat_div.html("登陆在线");
    //     $("#token").val(opcall.cfg.token);
    //     $("#login").attr("disabled", true);
    //     $("#logout").attr("disabled", false);
    //     $("#list_agent").attr("disabled", false)
    //     $("#list_queue").attr("disabled", false)
    //   });
    //
    //   opcall.lib.event("logout").unsubscribe().subscribe(function () {
    //     console.log("坐席登出成功");
    //     // this.stat_div.html("坐席登出");
    //     $("#login").attr("disabled", false);
    //     $("#logout").attr("disabled", true);
    //     $("#hangup").attr("disabled", true);
    //     $("#hold").attr("disabled", true);
    //     $("#unhold").attr("disabled", true);
    //     $("#tranfer").attr("disabled", true);
    //     $("#monitor").attr("disabled", true);
    //     $("#breakin").attr("disabled", true);
    //     $("#breakin2threeway").attr("disabled", true);
    //
    //     $("#status").attr("disabled", true);
    //     $("#checkout").attr("disabled", true);
    //     $("#checkin").attr("disabled", true);
    //     $("#dia").attr("disabled", true);
    //     $("#dia_num").attr("disabled", true);
    //     $("#switch_threeway").attr("disabled", true);
    //     $("#cancel_transfer").attr("disabled", true)
    //     $("#xfer2threeway").attr("disabled", true)
    //     $("#list_agent").attr("disabled", true)
    //     $("#list_queue").attr("disabled", true)
    //   });
    //
    //   opcall.lib.event("login_fail").unsubscribe().subscribe(function (data) {
    //     console.log(data.message);
    //     // this.stat_div.html("离线");
    //   });
    //
    //   opcall.lib.event("member_count").unsubscribe().subscribe(function (data) {
    //     $("#member_count").html(data);
    //   });
    //
    //   opcall.lib.event("idle").unsubscribe().subscribe(function () {
    //     // this.stat_div.html("空闲");
    //     $("#dia").attr("disabled", false);
    //     $("#dia_num").attr("disabled", false);
    //     $("#hangup").attr("disabled", true);
    //     $("#answer").attr("disabled", true);
    //     $("#hold").attr("disabled", true);
    //     $("#unhold").attr("disabled", true);
    //     $("#tranfer").attr("disabled", true);
    //     $("#blind_transfer").attr("disabled", true);
    //
    //     $("#cancel_transfer").attr("disabled", true)
    //     $("#xfer2threeway").attr("disabled", true)
    //
    //     $("#tranfer_queue").attr("disabled", true);
    //     $("#monitor").attr("disabled", true);
    //     $("#spy").attr("disabled", false);
    //     $("#breakin").attr("disabled", false);
    //     $("#breakin2threeway").attr("disabled", false);
    //     $("#switch_threeway").attr("disabled", true);
    //   });
    //
    //   // opcall.lib.event("ring").unsubscribe().subscribe(function (ext) {
    //   //   if(ext.agent_state=='Receiving'){
    //   //     that.ringVisible=true;
    //   //     that.caller=ext.peer;
    //   //     Bus.$emit('caller-value',ext.peer)
    //   //     setTimeout(()=> {
    //   //       Bus.$emit('caller-value',ext.peer)
    //   //     },2500)
    //   //   }
    //   //   // this.stat_div.html("振铃");
    //   //   $("#dia").attr("disabled", true);
    //   //   $("#dia_num").attr("disabled", true);
    //   //   $("#hangup").attr("disabled", false);
    //   //   $("#answer").attr("disabled", false);
    //   //   $("#spy").attr("disabled", true);
    //   //   $("#breakin").attr("disabled", true);
    //   //   $("#breakin2threeway").attr("disabled", true);
    //   //   $("#blind_transfer").attr("disabled", false);
    //   //   console.log("电话振铃了[此处完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
    //   // });
    //
    //   opcall.lib.event("busy").unsubscribe().subscribe(function (ext) {
    //     // this.stat_div.html("忙线");
    //     console.log("电话接通了[如果缺失ring事件，那么应该在answer中完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
    //     $("#dia").attr("disabled", true);
    //     $("#dia_num").attr("disabled", true);
    //     $("#hangup").attr("disabled", false);
    //     $("#answer").attr("disabled", true);
    //     $("#hold").attr("disabled", false);
    //     $("#unhold").attr("disabled", false);
    //     $("#tranfer").attr("disabled", false);
    //     $("#tranfer_queue").attr("disabled", false);
    //     $("#switch_threeway").attr("disabled", false);
    //     $("#cancel_transfer").attr("disabled", false)
    //     $("#xfer2threeway").attr("disabled", false)
    //     $("#blind_transfer").attr("disabled", false);
    //   });
    //
    //   opcall.lib.event("xfer_busy").unsubscribe().subscribe(function (ext) {
    //     // this.stat_div.html("忙线");
    //     // console.log("电话转接已经接通，来电号码" + ext.xfer_source + "，您的号码：" + ext.ext_no + "，第三方号码:" + ext.xfer_to + "转接随路:" + ext.xfer_info);
    //   });
    //
    //   opcall.lib.event("available").unsubscribe().subscribe(function (ext) {
    //     opcall.lib.log("receive available");
    //     if (opcall.cfg.state.call_state == 'idle') {
    //       // this.stat_div.html("空闲");
    //     }
    //
    //     $("#status").attr("disabled", false);
    //     $("#checkout").attr("disabled", false);
    //     $("#checkin").attr("disabled", true);
    //   });
    //   /**
    //    * 处理坐席签出消息
    //    */
    //   opcall.lib.event("unavailable").unsubscribe().subscribe(function (ext) {
    //     opcall.lib.log("receive unavailable:" + ext.state_cause_description);
    //     if (opcall.cfg.state.call_state == 'idle') {
    //       var desc = ext.state_cause_description ? ext.state_cause_description : (ext.state_cause == "pause" ? "话后" : "小休")
    //       // this.stat_div.html(desc);
    //     }
    //
    //     $("#status").attr("disabled", true);
    //     $("#checkout").attr("disabled", true);
    //     $("#checkin").attr("disabled", false);
    //   });
    //
    //   /**
    //    * 处理坐席保持事件
    //    */
    //   opcall.lib.event("hold").unsubscribe().subscribe(function (ext) {
    //     opcall.lib.log("receive hold");
    //     // this.stat_div.html("电话保持");
    //     $("#hold").attr("disabled", false);
    //     $("#unhold").attr("disabled", false);
    //   });
    //
    //
    //   /**
    //    * 处理分机离线事件
    //    */
    //   opcall.lib.event("offline").unsubscribe().subscribe(function (ext) {
    //     opcall.lib.log("receive offline");
    //     // this.stat_div.html("分机离线");
    //
    //     $("#hangup").attr("disabled", true);
    //     $("#hold").attr("disabled", true);
    //     $("#unhold").attr("disabled", true);
    //     $("#tranfer").attr("disabled", true);
    //     $("#monitor").attr("disabled", true);
    //     $("#breakin").attr("disabled", true);
    //     $("#status").attr("disabled", true);
    //     $("#checkout").attr("disabled", true);
    //     $("#checkin").attr("disabled", true);
    //     $("#dia").attr("disabled", true);
    //     $("#dia_num").attr("disabled", true);
    //     $("#tranfer_queue").attr("disabled", true);
    //     $("#cancel_transfer").attr("disabled", true)
    //     $("#xfer2threeway").attr("disabled", true)
    //   });
    // },
    // hold() {
    //   /**
    //    * 参数1：表示要保持的分机号，-表示当前登录的分机号
    //    * 参数2：是否要确认保持的提示文字，null表示不确认
    //    * 参数3：保持完成后的回调函数，在回调函数中，会回调一个data的Json对象，用于表示是否成功，
    //    *        他包含了以下属性：
    //    *      success:  是否成功，true表示成功，false表示失败，
    //    *      message： 失败原因
    //    *
    //    */
    //   this.opcall.cli.hold('-', null, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // unhold() {
    //   this.opcall.cli.unhold('-', null, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // hangup() {
    //   this.opcall.cli.hangup('-', null, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // answer() {
    //   this.opcall.cli.answer('-', null, function (data) {
    //     //console.log(data.message);
    //   });
    // },
    // //咨询转接
    // blind_transfer() {
    //   //46012是您要转接的号码
    //   var to = prompt("请输入目标号码：");
    //   if (to) {
    //     this.opcall.cli.blind_transfer('-', to, function (data) {
    //       console.log(data.message);
    //     });
    //   }
    // },
    // //咨询转接
    // transfer() {
    //   //46012是您要转接的号码
    //   var to = prompt("请输入目标号码：");
    //   if (to) {
    //     this.opcall.cli.transfer('-', to, function (data) {
    //       console.log(data.message);
    //     });
    //   }
    // },
    // //咨询转转三方
    // xfer2threeway() {
    //   this.opcall.cli.xfer2threeway(function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //设置转接随路
    // set_var() {
    //   var msg = prompt("请输入随路数据：");
    //   if (msg) {
    //     this.opcall.cli.set_var('-', 'xfer_info', msg, 'both', function (data) {
    //       console.log(data.message);
    //     });
    //   }
    // },
    // //取消咨询转
    // cancel_transfer() {
    //   this.opcall.cli.cancelxfer(function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //转接到队列
    // transfer_queue() {
    //   //46012是您要转接的号码
    //   var to = prompt("请输入目标队列(格式为queue_技能组id@技能组名称)：");
    //   if (to) {
    //     this.opcall.cli.transfer_queue('-', to, function (data) {
    //       console.log(data.message);
    //     });
    //   }
    // },
    // //监听
    // spy() {
    //   //46012是被监听者分机号码
    //   var to = prompt("请输入监听号码：");
    //   this.opcall.cli.spy(to, '-', function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //强插
    // breakin() {
    //   //46012是被监听者分机号码
    //   var to = prompt("请输入要强插的号码：");
    //   this.opcall.cli.breakin(to, '-', function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //插话
    // breakin2threeway() {
    //   //46012是被监听者分机号码
    //   var to = prompt("请输入要插话的号码：");
    //   this.opcall.cli.breakin2threeway('-', to, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // switch_threeway(mod) {
    //   this.opcall.cli.switch_threeway('-', mod, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // join_queue(queue) {
    //   //46012是被监听者分机号码
    //   this.opcall.cli.join_queue('-', queue, function (data) {
    //     // console.log(data.message);
    //   });
    // },
    // transfer_script(script_name) {
    //   //46012是被监听者分机号码
    //   var param = new Array("param1", "param2", "param3");
    //   if (script_name == "flycua_ivr_pay.lua") {
    //     param = new Array('http://3323232', 'appid', 'securcry');
    //   }
    //   this.opcall.cli.transfer_script('-', script_name, param, function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //强插
    // agent_state(status) {
    //   this.opcall.cli.adv_agent_status('-', status, '', function (data) {
    //     console.log(data.message);
    //   });
    // },
    // //获取坐席列表
    // list_agent() {
    //   this.opcall.cli.list_agent(function (data) {
    //     console.log(JSON.stringify(data))
    //   });
    // },
    // //获取坐席列表
    // list_queue() {
    //   this.opcall.cli.list_queue(function (data) {
    //     console.log(JSON.stringify(data))
    //   });
    // },

    //通话中心初始化
    init() {
      //将IP和账号密码替换为实际的IP和账号、密码
      let cti_config = {
        debug: true,
        baseurl: "http://183.214.195.130:85/atstar/client.php",
        user: this.user,
        password: this.password,
        login_ext_no: this.login_ext_no
      };
      this.opcall = new call(cti_config)
      console.log(this.opcall)
      this.msg_div = $("#msg_div");
      this.stat_div = $("#stat_div");
      // this.alarmModal();
      // this.bind_event(this.opcall);
    },
    //登录
    login() {
      try {
        this.init();
        this.opcall.lib.startup();
      } catch (err) {
        alert(err);
      }
    },
    //登出
    logout() {
      this.pages = 'alarmMap'
      this.$emit('alarmMap')
      this.opcall.lib.stop();
    },
    //拨号
    dial(num) {
      //46012是被监听者分机号码
      this.$message.info("正在拨打电话，请稍候")
      this.opcall.cli.dial('-', num, function (data) {
        // console.log(data.message);
      });
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
          + " " + date.getHours() + seperator2 + date.getMinutes()
          + seperator2 + date.getSeconds();
      return currentdate;
    },
    monitoring(){
      if(this.videoDevice){
        this.videoVisible=true;
      }else{
        this.videoVisible=false;
        this.$message.error("该电梯无物联网设备")
      }
    },
    onChange_1 (value, dateString) {
      this.alarmfiles.calledtime=dateString
    },
    range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    initSpan() {
      this.getAlarmFiles();
      // this.getEventrecord();
      // this.getLiftrescue();
    },
    initData(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.caller=logininfo.phonextnum;
        this.areacode=logininfo.areacode;
        this.user=logininfo.phonename;
        this.password=logininfo.phonepassword;
        this.login_ext_no=logininfo.phonextnum;
      }
      this.login()
    },
    phoneCall(tel,caller,index){
      this.index=index
      this.information[index]='true'
      // console.log(this.index)
      // console.log(this.information)
      if(tel!=''&&caller!=''){
        let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"正在拨打"+caller+":"+tel+"(操作员："+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
        if(this.alarmfiles.rescueprog==null){
          this.alarmfiles.rescueprog = record;
        }else{
          this.alarmfiles.rescueprog += record
        }
      }
      this.dial(tel)
      if(caller==this.queryParams.maintenanceperson1||caller==this.queryParams.maintenanceperson2||caller==this.queryParams.areamanager||caller==this.queryParams.depmanager||caller==this.formDatas.maintenancedepname){
        this.alarmfiles.maintenancedeptime=moment(new Date()).format("YYYYMMDDHHmmss");
        this.alarmfiles.maintenancedepcontime=this.getConsutime(this.alarmfiles.calledtime,new Date())
      }
      if(caller==this.queryParams.stationperson1||caller==this.queryParams.stationperson2||caller==this.queryParams.pristationperson||caller==this.queryParams.pristation){
        this.alarmfiles.pristationtime=moment(new Date()).format("YYYYMMDDHHmmss");
        this.alarmfiles.pristationcontime=this.getConsutime(this.alarmfiles.calledtime,new Date())
      }
      if(caller==this.alarmfiles.specialist){
        this.alarmfiles.rescuespecialisttime=moment(new Date()).format("YYYYMMDDHHmmss");
        this.alarmfiles.rescuespecialistscontime=this.getConsutime(this.alarmfiles.calledtime,new Date())
      }
      this.phoneCallTime=new Date();
    },
    //发送短信
    sendmessage(tel){
      //报警发出
      let params = {
        userdepid:this.queryParams.userdepid,
        maintenancedepid:this.queryParams.maintenancedepid,
        areacode:this.areacode,
        number:tel,
        signName:this.signName,
        templateCode:this.sendRescuer,
        templateParam:{
          Add:this.formDatas.adress,
          resuceCode:this.formDatas.liftrescue,
          time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        }
      }
      sendMessage(params)
    },
    messageagain(dep){
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，电梯发生(故障/报警),请尽快处理，呼救人："+this.alarmfiles.alarmcaller+"，联系电话："+this.alarmfiles.callertel+"短信已经发送"+"\n"
      if(dep=="userdep"){
        if(this.queryParams.safeperson!=null&&this.queryParams.safeperson!=''&&this.queryParams.safephone!=null&&this.queryParams.safephone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.safephone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.formDatas.userdepname!=null&&this.formDatas.userdepname!=''&&this.queryParams.telphone!=null&&this.queryParams.telphone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.telphone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
      }else if(dep=="maintenancedep"){
        if(this.formDatas.maintenancedepname!=null&&this.formDatas.maintenancedepname!=''&&this.formDatas.maintenancedephone!=null&&this.formDatas.maintenancedephone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.maintenancedephone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.maintenanceperson1!=null&&this.queryParams.maintenanceperson1!=''&&this.queryParams.maintenancephone1!=null&&this.queryParams.maintenancephone1!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.maintenancephone1,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.maintenanceperson2!=null&&this.queryParams.maintenanceperson2!=''&&this.queryParams.maintenancephone2!=null&&this.queryParams.maintenancephone2!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.maintenancephone2,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.areamanager!=null&&this.queryParams.areamanager!=''&&this.queryParams.areamanagerphone!=null&&this.queryParams.areamanagerphone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.areamanagerphone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.depmanager!=null&&this.queryParams.depmanager!=''&&this.queryParams.depmanagerphone!=null&&this.queryParams.depmanagerphone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.depmanagerphone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
      }else if(dep=="rescuedep"){
        if(this.queryParams.stationperson1!=null&&this.queryParams.stationperson1!=''&&this.queryParams.stationphone1!=null&&this.queryParams.stationphone1!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.stationphone1,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.stationperson2!=null&&this.queryParams.stationperson2!=''&&this.queryParams.stationphone2!=null&&this.queryParams.stationphone2!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.stationphone2,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.pristationperson!=null&&this.queryParams.pristationperson!=''&&this.queryParams.pristationperphone!=null&&this.queryParams.pristationperphone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.pristationperphone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
        if(this.queryParams.pristation!=null&&this.queryParams.pristation!=''&&this.queryParams.pristationphone!=null&&this.queryParams.pristationphone!=''){
          let param2 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.queryParams.pristationphone,
            signName:this.signName,
            templateCode:this.sendRescuer,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode:this.formDatas.liftrescue,
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param2);}
      }
      if(this.alarmfiles.message==null){
        this.alarmfiles.message = record;
      }else{
        this.alarmfiles.message += record
      }
    },
    //响应结果
    connect(tel,caller,conn){
      if(tel!=''&&caller!=''&&conn!=''){
        let connvalue='';
        if(conn==0){
          connvalue="未接通"
        }else if(conn==1){
          connvalue="接通未排人"
        }else if(conn==2){
          this.alarmfiles.disptime=moment(new Date()).format("YYYYMMDDHHmmss");
          this.alarmfiles.dispconstime=this.getConsutime(this.alarmfiles.calledtime,new Date())
          this.constimes=this.getConsutime(this.alarmfiles.calledtime,new Date())
          connvalue="接通已派人";
          let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：已安排救援人员赶赴现场，救援人员："+caller+"，联系方式："+tel+"，请耐心等待。"+"短信已经发送给"+this.alarmfiles.alarmcaller+"\n"
              +moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，在"+moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+"电梯发生(故障/报警),请尽快处理。"+"短信已经发送给"+caller+"\n"
          //报警安抚被困人员
          let param1 = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.alarmfiles.callertel,
            signName:this.signName,
            templateCode:this.sendTrapped,
            templateParam:{
              time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              name:caller,
              phoneNum:tel
            }
          }
          sendMessage(param1)
          let params = {
            alarmid:this.alarmfiles.alarmid,
            rescueperson:caller,
            maintenancegroupid:this.alarmfiles.maintenancegroupid,
            maintenancedeptime:this.alarmfiles.maintenancedeptime,
            maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
            signforflag:1,
            arriveflag:0,
            rescuendflag:0,
            stopflag:1,
            orderendflag:0,
          }
          modifyAlarmFiles(params)
          //发送给救援人员
          for(var i=0;i<this.callers.length;i++) {
            if(this.callers[i]){
              if(this.callers[i]==this.alarmfiles.callertel){
                continue
              }
              let param = {
                userdepid:this.queryParams.userdepid,
                maintenancedepid:this.queryParams.maintenancedepid,
                areacode:this.areacode,
                number:this.callers[i],
                signName:this.signName,
                templateCode:this.sendRescuer,
                templateParam:{
                  Add:this.formDatas.adress,
                  resuceCode: this.formDatas.liftrescue,
                  time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                }
              }
              sendMessage(param)
            }
          }
          if(this.alarmfiles.message==null){
            this.alarmfiles.message = record;
          }else{
            this.alarmfiles.message += record
          }
        }else if(conn==3){
          connvalue="信息不正确"
        }
        let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+connvalue+"("+"联系人:"+caller+"，"+"联系人电话："+tel+")"+"\n"
        if(this.alarmfiles.rescueprog==null){
          this.alarmfiles.rescueprog = record;
        }else{
          this.alarmfiles.rescueprog += record
        }
      }
    },
    getWeftFiles(){
      let params = {
        // userdepid:this.formDatas.userdepid,
        liftid:this.formDatas.liftid
      };
      getLiftinfoById(params).then(res => {
        if(res && res.returncode == '0') {
          this.queryParams.userdepid=res.item.userdepid,
          this.queryParams.maintenancedepid=res.item.maintenancedepid,
          this.queryParams.safeperson=res.item.userdepLists.safeperson,
          this.queryParams.safephone=res.item.userdepLists.safephone,
          this.queryParams.userdepname=res.item.userdepLists.userdepname
          this.queryParams.telphone=res.item.userdepLists.telphone,
          this.queryParams.maintenanceperson1=res.item.maintenancedepLists.maintenanceperson1,
          this.queryParams.maintenancephone1=res.item.maintenancedepLists.maintenancephone1,
          this.queryParams.maintenanceperson2=res.item.maintenancedepLists.maintenanceperson2,
          this.queryParams.maintenancephone2=res.item.maintenancedepLists.maintenancephone2,
          this.queryParams.areamanager=res.item.maintenancedepLists.areamanager,
          this.queryParams.areamanagerphone=res.item.maintenancedepLists.areamanagerphone,
          this.queryParams.depmanager=res.item.maintenancedepLists.depmanager,
          this.queryParams.depmanagerphone=res.item.maintenancedepLists.depmanagerphone,
          this.queryParams.stationperson1=res.item.rescuedepLists.stationperson1,
          this.queryParams.stationphone1=res.item.rescuedepLists.stationphone1,
          this.queryParams.stationperson2=res.item.rescuedepLists.stationperson2,
          this.queryParams.stationphone2=res.item.rescuedepLists.stationphone2,
          this.queryParams.pristationperson=res.item.rescuedepLists.pristationperson,
          this.queryParams.pristationperphone=res.item.rescuedepLists.pristationperphone,
          this.queryParams.pristation=res.item.rescuedepLists.pristation,
          this.queryParams.pristationphone=res.item.rescuedepLists.pristationphone,
          this.queryParams.maintenancedep=res.item.rescuedepLists.maintenancedep,
          this.queryParams.maintenancedephone=res.item.rescuedepLists.maintenancedephone,
          this.queryParams.maintenancegroupid=res.item.maintenancegroupid,
          this.queryParams.rescueperson=res.item.rescueperson,
          this.queryParams.rescuephone=res.item.rescuephone,
          this.callers.push(this.caller)
          //使用单位
          if(res.item.userdeppreson!=''&&res.item.userdeppersonphone!=''){
            if(this.callers.indexOf(res.item.userdeppersonphone) > -1){
            }else{
              this.callers.push(res.item.userdeppersonphone)
            }
          }
          if(res.item.userdepLists.userdepname!=''&&res.item.userdepLists.telphone!=''){
            if(this.callers.indexOf(res.item.userdepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.userdepLists.telphone)
            }
          }
          if(res.item.userdepLists.safeperson!=''&&res.item.userdepLists.safephone!=''){
            if(this.callers.indexOf(res.item.userdepLists.safephone) > -1){
            }else{
              this.callers.push(res.item.userdepLists.safephone)
            }
          }
          //维保单位
          if(res.item.maintenancedepLists.userdepname!=''&&res.item.maintenancedepLists.telphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.telphone)
            }
          }
          if(res.item.maintenancedepLists.maintenanceperson1!=''&&res.item.maintenancedepLists.maintenancephone1!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.maintenancephone1) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.maintenancephone1)
            }
          }
          if(res.item.maintenancedepLists.maintenanceperson2!=''&&res.item.maintenancedepLists.maintenancephone2!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.maintenancephone2) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.maintenancephone2)
            }
          }
          if(res.item.maintenancedepLists.areamanager!=''&&res.item.maintenancedepLists.areamanagerphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.areamanagerphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.areamanagerphone)
            }
          }
          if(res.item.maintenancedepLists.depmanager!=''&&res.item.maintenancedepLists.depmanagerphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.depmanagerphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.depmanagerphone)
            }
          }
          if(res.item.rescueperson!='' && res.item.rescuephone!='') {
            if(res.item.rescuephone.length<13){
              if(this.callers.indexOf(res.item.rescuephone) > -1){
              }else{
                this.callers.push(res.item.rescuephone)
              }
            }else{
              let strs = res.item.rescuephone.split(",");
              for(var n=0;n<strs.length;n++){
                if(this.callers.indexOf(strs[n]) > -1){
                }else{
                  this.callers.push(strs[n])
                }
              }
            }
          }
          //救援单位
          if(res.item.rescuedepLists.userdepname!=''&&res.item.rescuedepLists.telphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.telphone)
            }
          }
          if(res.item.rescuedepLists.stationperson1!=''&&res.item.rescuedepLists.stationphone1!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.stationphone1) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.stationphone1)
            }
          }
          if(res.item.rescuedepLists.stationperson2!=''&&res.item.rescuedepLists.stationphone2!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.stationphone2) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.stationphone2)
            }
          }
          if(res.item.rescuedepLists.pristationperson!=''&&res.item.rescuedepLists.pristationperphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.pristationperphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.pristationperphone)
            }
          }
          if(res.item.rescuedepLists.pristation!=''&&res.item.rescuedepLists.pristationphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.pristationphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.pristationphone)
            }
          }
          let params ={
            maintenancegroupid:res.item.maintenancegroupid
          }
          getMentenacegroupById(params).then(res=>{
            if(res && res.returncode == '0') {
              console.log(res)
              for(var i=0;i<res.item.memberList.length;i++){
                if(this.callers.indexOf(res.item.memberList[i].phonenum) > -1){
                }else{
                  this.callers.push(res.item.memberList[i].phonenum)
                }
              }
            }
          })
        }
      }).catch(err => {
      })
    },
    getEventrecord(){
      let params = {
        liftrescue: this.codeRescue[0],
        eventcode:"02",
        eventtype:2,
        eventstatus:1
      };
      getEventrecordListByCondition(params).then(res=>{
        if(res && res.returncode == '0'){
          this.alarmfiles.eventrecordid=res.item[0].eventrecordid
          this.alarmfiles.devicenum=res.item[0].devicenum
          this.alarmfiles.eventstarttime=res.item[0].eventstarttime
          this.videoDevice=res.item[0].devicenum
        }
      })
    },
    getLiftrescue() {
      let params = {
        liftrescue: this.alarmfiles.liftrescue,
        // userdepid:this.unitId[0],
      };
      getLiftinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.formDatas.liftrescue=res.item[0].liftrescue,
              this.formDatas.adress=res.item[0].adress,
              this.formDatas.userdepid=res.item[0].userdepid,
              this.formDatas.userdepname=res.item[0].userdepname,
              this.formDatas.maintenancedepname=res.item[0].maintenancedepname,
              this.alarmfiles.liftrescue=res.item[0].liftrescue,
              this.alarmfiles.adress=res.item[0].adress,
              this.formDatas.liftid=res.item[0].liftid,
              this.alarmfiles.devicenum=res.item[0].devicenum,
              this.getWeftFiles();
        }
      }).catch(err => {
      })
    },
    getAlarmFiles(){
      let params = {
        // ...this.alarmfiles
        // eventrecordid:this.eventRecordId[0]
        alarmid:this.AlarmId
      };
      getAlarmFilesListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.alarmfiles.alarmid=res.item[0].alarmid
          this.alarmfiles.trappednum=res.item[0].trappednum
          this.alarmfiles.alarmtype=res.item[0].alarmtype
          this.alarmfiles.alarmcaller=res.item[0].alarmcaller
          this.alarmfiles.callertel=res.item[0].callertel
          this.alarmfiles.liftrescue=res.item[0].liftrescue
          this.alarmfiles.adress=res.item[0].adress
          this.alarmfiles.liftfault=res.item[0].liftfault
          this.alarmfiles.calledtime=res.item[0].calledtime
          this.alarmfiles.maintenancedeptime=res.item[0].maintenancedeptime
          this.alarmfiles.maintenancedepcontime=res.item[0].maintenancedepcontime
          this.alarmfiles.pristationtime=res.item[0].pristationtime
          this.alarmfiles.pristationcontime=res.item[0].pristationcontime
          this.alarmfiles.rescuespecialisttime=res.item[0].rescuespecialisttime
          this.alarmfiles.rescuespecialistscontime=res.item[0].rescuespecialistscontime
          this.alarmfiles.rescueprog=res.item[0].rescueprog
          this.constimes=res.item[0].constime
          this.alarmfiles.prearrtime=res.item[0].prearrtime
          this.alarmfiles.message=res.item[0].message
          this.alarmfiles.disptime=res.item[0].disptime
          this.alarmfiles.dispconstime=res.item[0].dispconstime
          this.alarmfiles.resperarrtime=res.item[0].resperarrtime
          this.alarmfiles.resperconstime=res.item[0].resperconstime
          this.alarmfiles.savetime=res.item[0].savetime
          this.alarmfiles.rescueconstime=res.item[0].rescueconstime
          this.alarmfiles.savetime=res.item[0].savetime
          this.alarmfiles.rescueconstime=res.item[0].rescueconstime
          this.alarmfiles.lifteventype=res.item[0].lifteventype
          this.alarmfiles.faultype=res.item[0].faultype
          this.alarmfiles.secondfaultype=res.item[0].secondfaultype
          this.alarmfiles.faultdesc=res.item[0].faultdesc
          this.alarmfiles.rescuedby=res.item[0].rescuedby
          this.alarmfiles.rescueres=res.item[0].rescueres
          this.alarmfiles.rescuenum=res.item[0].rescuenum
          this.alarmfiles.backtype=res.item[0].backtype
          this.alarmfiles.arrtime=res.item[0].arrtime
          this.alarmfiles.mainteva=res.item[0].mainteva
          this.alarmfiles.miantcomeva=res.item[0].miantcomeva
          this.alarmfiles.safeva=res.item[0].safeva
          this.alarmfiles.rescuexplain=res.item[0].rescuexplain
          this.alarmfiles.safeconn=res.item[0].safeconn
          this.alarmfiles.userdepconn=res.item[0].userdepconn
          this.alarmfiles.maintenanceper1conn=res.item[0].maintenanceper1conn
          this.alarmfiles.maintenanceper2conn=res.item[0].maintenanceper2conn
          this.alarmfiles.areamanagerconn=res.item[0].areamanagerconn
          this.alarmfiles.depmanagerconn=res.item[0].depmanagerconn
          this.alarmfiles.maintenancedepconn=res.item[0].maintenancedepconn
          this.alarmfiles.stationperson1conn=res.item[0].stationperson1conn
          this.alarmfiles.stationperson2conn=res.item[0].stationperson2conn
          this.alarmfiles.pristationpersonconn=res.item[0].pristationpersonconn
          this.alarmfiles.pristationconn=res.item[0].pristationconn
          this.alarmfiles.conn_119=res.item[0].conn_119
          this.alarmfiles.conn_120=res.item[0].conn_120
          this.alarmfiles.conn_110=res.item[0].conn_110
          this.alarmfiles.rescuespecialistsconn=res.item[0].rescuespecialistsconn
          this.alarmfiles.panel=res.item[0].panel
          this.alarmfiles.specialist=res.item[0].specialist
          this.alarmfiles.specialistphone=res.item[0].specialistphone
          this.alarmfiles.maintainback=res.item[0].maintainback
          this.alarmfiles.rescueback=res.item[0].rescueback
          this.alarmfiles.maintenancegroupid=res.item[0].maintenancegroupid
          this.alarmfiles.eventcode=res.item[0].eventcode
          this.alarmfiles.devicenum=res.item[0].devicenum
          this.videoDevice=res.item[0].devicenum
          this.callers.push(this.alarmfiles.callertel)
          if(res.item[0].signforflag==0){
            this.signforflag=false
          }else{
            this.signforflag=true
          }
          if(res.item[0].arriveflag==0){
            this.arriveflag=false
          }else{
            this.arriveflag=true
          }
          if(res.item[0].rescuendflag==0){
            this.rescuendflag=false
          }else{
            this.rescuendflag=true
          }
          if(res.item[0].stopflag==0){
            this.stopflag=false
          }else{
            this.stopflag=true
          }
          if(res.item[0].orderendflag==0){
            this.orderendflag=false
            if(res.item[0].stopflag==0){
              this.saveflag=false
              this.disabled=true
            }
          }else{
            this.orderendflag=true
            this.show8=true;
            this.show9=true;
          }
        }
        this.getLiftrescue();
      }).catch(err => {
      })
    },
    onReasonChange(value) {
      if(value=='0')  {this.alarmfiles.faultype="人为原因";this.selcity = this.secondreson[0];}
      if(value=='1')  {this.alarmfiles.faultype="外部原因";this.selcity = this.secondreson[1];}
      if(value=='2')  {this.alarmfiles.faultype="门系统";this.selcity = this.secondreson[2];}
      if(value=='3')  {this.alarmfiles.faultype="曳引系统";this.selcity = this.secondreson[3];}
      if(value=='4')  {this.alarmfiles.faultype="导向系统";this.selcity = this.secondreson[4];}
      if(value=='5')  {this.alarmfiles.faultype="轿厢";this.selcity = this.secondreson[5];}
      if(value=='6')  {this.alarmfiles.faultype="控制系统";this.selcity = this.secondreson[6];}
      if(value=='7')  {this.alarmfiles.faultype="电气系统";this.selcity = this.secondreson[7];}
      if(value=='8')  {this.alarmfiles.faultype="安全保护装置";this.selcity = this.secondreson[8];}
      this.seconds=this.selcity[0];
    },
    //获取录音
    getRecords(){
      let params = {
        caller:this.callers,
        begintime:moment((moment(this.alarmfiles.calledtime,"YYYY-MM-DD HH:mm:ss").unix()-90)*1000).format("YYYYMMDDHHmmss"),
        endtime:this.alarmfiles.savetime,
      }
      this.detaildata=params
      this.checkTraceVisible=true
    },
    //切换显示平台还是APP图片
    appOrPlat(index){
      this.pc=index
      if(index==true){
        this.buttonIndex=1
      }else{
        this.buttonIndex=2
        this.getAPPImg()
      }
    },
    //困人图片
    getPic() {
      var that = this;
      for (let i = 1; i <= 3; i++) {
        (function (i) {
          setTimeout(function () {
            let params = {
              devicenum: that.alarmfiles.devicenum,
              eventtype: '2',
              eventcode: that.alarmfiles.eventcode,
              eventstarttime:  that.alarmfiles.calledtime,
              picNum: i
            }
            //请求图片接口并且对文件流进行处理
            viewUserImg(params).then((res) => {
              // var data = res.data;
              if(res){
                this.fileField =
                    "data:image/jpg;base64," +
                    btoa(
                        new Uint8Array(res).reduce(
                            (res, byte) => res + String.fromCharCode(byte),
                            ""
                        )
                    );
                that.urlList.push(this.fileField)
                if(res.byteLength>1000){
                  that.picVisible=true
                }
                if(that.urlList.length>0) {
                  if (that.urlList.length == 1) {
                    that.picUrl1 = that.urlList[0]
                  } else if (that.urlList.length ==2) {
                    that.picUrl2 = that.urlList[1]
                  } else if (that.urlList.length ==3) {
                    that.picUrl3 = that.urlList[2]
                  } else {
                    that.urlList = []
                    that.modalVisible = false
                  }
                  that.picVisible=true
                }
              }else{
                console.log("未查找到相应图片")
              }
            })
          }, 300 * i);
        })(i);
      }
    },
    //获取APP上传图片
    getAPPImg(){
      this.locaImgLists=[]
      this.imgLists=[]
      let params = {
        alarmid:this.alarmfiles.alarmid
      }
      findRescuepicByAlarmId(params).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length>0){
          this.imgVisible=true
          for(var i=0;i<res.item.length;i++){
            // if(res.item[i].pictype=='21'&&res.item[i].picurl!=''){
            //   this.locaImgLists.push(this.baseUrl+res.item[i].picurl)
            // }else if(res.item[i].picurl!=''){
            //   this.imgLists.push(this.baseUrl+res.item[i].picurl)
            // }
            if(res.item[i].pictype=='11'){
              this.img1=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='21'){
              this.img2=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='22'){
              this.video=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='23'){
              this.img3=this.baseUrl+res.item[i].picurl
            }
          }
        }else{
          this.imgVisible=false
          this.$message.error("未找到相应图片")
        }
      })
    },
    //计算耗时
    getConsutime(front,back){
      let time = (moment(back,"YYYYMMDDHHmmss").valueOf()-moment(front,"YYYYMMDDHHmmss").valueOf())/1000
      let min =  Math.floor(time%3600)
      let restime = Math.floor(time/3600) + "时" +  Math.floor(min/60) + "分"+  Math.floor(time%60) + "秒";
      return restime
    },
    //签收
    signFor(){
      this.constimes=this.getConsutime(this.alarmfiles.calledtime,new Date())
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"工单已签收"+"(操作员:"+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
      if(this.alarmfiles.rescueprog==null){
        this.alarmfiles.rescueprog = record;
      }else{
        this.alarmfiles.rescueprog += record
      }
      if(this.alarmfiles.calledtime==null){
        this.$message.info("请选择接警时间")
      }else if(this.alarmfiles.maintenanceper1conn==null&&this.alarmfiles.maintenanceper2conn==null&&this.alarmfiles.areamanagerconn==null&&this.alarmfiles.depmanagerconn==null&&this.alarmfiles.maintenancedepconn==null){
        this.$message.info("请选择响应人员")
      }else{
        let params = {
          alarmid: this.alarmfiles.alarmid,
          eventrecordid:this.eventRecordId[0],
          trappednum:this.alarmfiles.trappednum,
          alarmtype:this.alarmfiles.alarmtype,
          liftrescue:this.alarmfiles.liftrescue,
          adress:this.alarmfiles.adress,
          alarmcaller:this.alarmfiles.alarmcaller,
          callertel:this.alarmfiles.callertel,
          liftfault:this.alarmfiles.liftfault,
          calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
          pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          pristationcontime:this.alarmfiles.pristationcontime,
          rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
          rescueprog:this.alarmfiles.rescueprog,
          constime:this.constimes,
          prearrtime:this.alarmfiles.prearrtime,
          message:this.alarmfiles.message,
          disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          dispconstime:this.alarmfiles.dispconstime,
          resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          resperconstime:this.alarmfiles.resperconstime,
          savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescueconstime:this.alarmfiles.rescueconstime,
          lifteventype:this.alarmfiles.lifteventype,
          faultype:this.alarmfiles.faultype,
          secondfaultype:this.alarmfiles.secondfaultype,
          faultdesc:this.alarmfiles.faultdesc,
          rescuedby:this.alarmfiles.rescuedby,
          rescueres:this.alarmfiles.rescueres,
          rescuenum:this.alarmfiles.rescuenum,
          backtype:this.alarmfiles.backtype,
          arrtime:this.alarmfiles.arrtime,
          mainteva:this.alarmfiles.mainteva,
          miantcomeva:this.alarmfiles.miantcomeva,
          safeva:this.alarmfiles.safeva,
          rescuexplain:this.alarmfiles.rescuexplain,
          safeconn:this.alarmfiles.safeconn,
          userdepconn:this.alarmfiles.userdepconn,
          maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
          maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
          areamanagerconn:this.alarmfiles.areamanagerconn,
          depmanagerconn:this.alarmfiles.depmanagerconn,
          maintenancedepconn:this.alarmfiles.maintenancedepconn,
          stationperson1conn:this.alarmfiles.stationperson1conn,
          stationperson2conn:this.alarmfiles.stationperson2conn,
          pristationpersonconn:this.alarmfiles.pristationpersonconn,
          pristationconn:this.alarmfiles.pristationconn,
          conn_119:this.alarmfiles.conn_119,
          conn_120:this.alarmfiles.conn_120,
          conn_110:this.alarmfiles.conn_110,
          rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
          cancel:this.alarmfiles.cancel,
          signforflag:0,
          arriveflag:1,
          rescuendflag:0,
          stopflag:1,
          orderendflag:0,
          ordernum:this.alarmfiles.ordernum,
          flowstep:'20',
          maintainback: this.alarmfiles.maintainback,
          rescueback: this.alarmfiles.rescueback,
          endflag:'1',
          waitorder:'0',
          endding:'0',
          conductor:this.login_ext_no,
        }
        if(this.alarmfiles.alarmid==''||this.alarmfiles.alarmid==null){
          addAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.arriveflag=true;
              this.signforflag=false;
              this.$emit('change',true)
            }
          })
        }else{
          modifyAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.arriveflag=true;
              this.signforflag=false;
              this.$emit('change',true)
            }
          })
        }
      }
    },
    //响应人员抵达现场
    arrive(){
      this.alarmfiles.resperarrtime=moment(new Date()).format("YYYYMMDDHHmmss");
      this.alarmfiles.resperconstime=this.getConsutime(this.alarmfiles.disptime,new Date())
      this.constimes=this.getConsutime(this.alarmfiles.calledtime,new Date())
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"响应人员抵达现场"+"(操作员:"+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
      if(this.alarmfiles.rescueprog==null){
        this.alarmfiles.rescueprog = record;
      }else{
        this.alarmfiles.rescueprog += record
      }
      for(var i=0;i<this.callers.length;i++) {
        if(this.callers[i]){
          let param = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.callers[i],
            signName:this.signName,
            templateCode:this.sendArrived,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode: this.formDatas.liftrescue,
              time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param)
        }
      }
      let records=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，电梯发生(故障/报警)，当前救援人员已抵达现场。"+"短信已经发送"+"\n"
      if(this.alarmfiles.message==null){
        this.alarmfiles.message = records;
      }else{
        this.alarmfiles.message += records
      }
      if(this.alarmfiles.calledtime==null){
        this.$message.info("请选择接警时间")
      }else if(this.alarmfiles.maintenanceper1conn==null&&this.alarmfiles.maintenanceper2conn==null&&this.alarmfiles.areamanagerconn==null&&this.alarmfiles.depmanagerconn==null&&this.alarmfiles.maintenancedepconn==null){
        this.$message.info("请选择响应人员")
      }else {
        let params = {
          alarmid: this.alarmfiles.alarmid,
          eventrecordid:this.eventRecordId[0],
          trappednum:this.alarmfiles.trappednum,
          alarmtype:this.alarmfiles.alarmtype,
          liftrescue:this.alarmfiles.liftrescue,
          adress:this.alarmfiles.adress,
          alarmcaller:this.alarmfiles.alarmcaller,
          callertel:this.alarmfiles.callertel,
          liftfault:this.alarmfiles.liftfault,
          calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
          pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          pristationcontime:this.alarmfiles.pristationcontime,
          rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
          rescueprog:this.alarmfiles.rescueprog,
          constime:this.constimes,
          prearrtime:this.alarmfiles.prearrtime,
          message:this.alarmfiles.message,
          disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          dispconstime:this.alarmfiles.dispconstime,
          resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          resperconstime:this.alarmfiles.resperconstime,
          savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescueconstime:this.alarmfiles.rescueconstime,
          lifteventype:this.alarmfiles.lifteventype,
          faultype:this.alarmfiles.faultype,
          secondfaultype:this.alarmfiles.secondfaultype,
          faultdesc:this.alarmfiles.faultdesc,
          rescuedby:this.alarmfiles.rescuedby,
          rescueres:this.alarmfiles.rescueres,
          rescuenum:this.alarmfiles.rescuenum,
          backtype:this.alarmfiles.backtype,
          arrtime:this.alarmfiles.arrtime,
          mainteva:this.alarmfiles.mainteva,
          miantcomeva:this.alarmfiles.miantcomeva,
          safeva:this.alarmfiles.safeva,
          rescuexplain:this.alarmfiles.rescuexplain,
          safeconn:this.alarmfiles.safeconn,
          userdepconn:this.alarmfiles.userdepconn,
          maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
          maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
          areamanagerconn:this.alarmfiles.areamanagerconn,
          depmanagerconn:this.alarmfiles.depmanagerconn,
          maintenancedepconn:this.alarmfiles.maintenancedepconn,
          stationperson1conn:this.alarmfiles.stationperson1conn,
          stationperson2conn:this.alarmfiles.stationperson2conn,
          pristationpersonconn:this.alarmfiles.pristationpersonconn,
          pristationconn:this.alarmfiles.pristationconn,
          conn_119:this.alarmfiles.conn_119,
          conn_120:this.alarmfiles.conn_120,
          conn_110:this.alarmfiles.conn_110,
          rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
          cancel:this.alarmfiles.cancel,
          signforflag:0,
          arriveflag:0,
          rescuendflag:1,
          stopflag:1,
          orderendflag:0,
          ordernum:this.alarmfiles.ordernum,
          flowstep:'30',
          maintainback: this.alarmfiles.maintainback,
          rescueback: this.alarmfiles.rescueback,
          endflag:'1',
          waitorder:'0',
          endding:'0'
        }
        if (this.alarmfiles.alarmid == '' || this.alarmfiles.alarmid == null) {
          addAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.arriveflag=false;
              this.rescuendflag=true;
              this.$emit('change',true)
            }
          })
        } else {
          modifyAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.arriveflag=false;
              this.rescuendflag=true;
              this.$emit('change',true)
            }
          })
        }
      }
    },
    //救援结束
    rescuend(){
      this.alarmfiles.savetime=moment(new Date()).format("YYYYMMDDHHmmss");
      this.alarmfiles.rescueconstime=this.getConsutime(this.alarmfiles.resperarrtime,new Date())
      this.constimes=this.getConsutime(this.alarmfiles.calledtime,new Date())
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"救援结束"+"(操作员:"+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
      if(this.alarmfiles.rescueprog==null){
        this.alarmfiles.rescueprog = record;
      }else{
        this.alarmfiles.rescueprog += record
      }
      for(var i=0;i<this.callers.length;i++) {
        if(this.callers[i]){
          let param = {
            userdepid:this.queryParams.userdepid,
            maintenancedepid:this.queryParams.maintenancedepid,
            areacode:this.areacode,
            number:this.callers[i],
            signName:this.signName,
            templateCode:this.sendRescuend,
            templateParam:{
              Add:this.formDatas.adress,
              resuceCode: this.formDatas.liftrescue,
              time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          sendMessage(param)
        }
      }
      let records=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，电梯发生(故障/报警)，当前救援任务已完成。"+"短信已经发送"+"\n"
      if(this.alarmfiles.message==null){
        this.alarmfiles.message = records;
      }else{
        this.alarmfiles.message += records
      }
      if(this.alarmfiles.calledtime==null){
        this.$message.info("请选择接警时间")
      }else if(this.alarmfiles.maintenanceper1conn==null&&this.alarmfiles.maintenanceper2conn==null&&this.alarmfiles.areamanagerconn==null&&this.alarmfiles.depmanagerconn==null&&this.alarmfiles.maintenancedepconn==null){
        this.$message.info("请选择响应人员")
      }else{
        let params = {
          alarmid: this.alarmfiles.alarmid,
          eventrecordid:this.eventRecordId[0],
          trappednum:this.alarmfiles.trappednum,
          alarmtype:this.alarmfiles.alarmtype,
          liftrescue:this.alarmfiles.liftrescue,
          adress:this.alarmfiles.adress,
          alarmcaller:this.alarmfiles.alarmcaller,
          callertel:this.alarmfiles.callertel,
          liftfault:this.alarmfiles.liftfault,
          calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
          pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          pristationcontime:this.alarmfiles.pristationcontime,
          rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
          rescueprog:this.alarmfiles.rescueprog,
          constime:this.constimes,
          prearrtime:this.alarmfiles.prearrtime,
          message:this.alarmfiles.message,
          disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          dispconstime:this.alarmfiles.dispconstime,
          resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          resperconstime:this.alarmfiles.resperconstime,
          savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescueconstime:this.alarmfiles.rescueconstime,
          lifteventype:this.alarmfiles.lifteventype,
          faultype:this.alarmfiles.faultype,
          secondfaultype:this.alarmfiles.secondfaultype,
          faultdesc:this.alarmfiles.faultdesc,
          rescuedby:this.alarmfiles.rescuedby,
          rescueres:this.alarmfiles.rescueres,
          rescuenum:this.alarmfiles.rescuenum,
          backtype:this.alarmfiles.backtype,
          arrtime:this.alarmfiles.arrtime,
          mainteva:this.alarmfiles.mainteva,
          miantcomeva:this.alarmfiles.miantcomeva,
          safeva:this.alarmfiles.safeva,
          rescuexplain:this.alarmfiles.rescuexplain,
          safeconn:this.alarmfiles.safeconn,
          userdepconn:this.alarmfiles.userdepconn,
          maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
          maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
          areamanagerconn:this.alarmfiles.areamanagerconn,
          depmanagerconn:this.alarmfiles.depmanagerconn,
          maintenancedepconn:this.alarmfiles.maintenancedepconn,
          stationperson1conn:this.alarmfiles.stationperson1conn,
          stationperson2conn:this.alarmfiles.stationperson2conn,
          pristationpersonconn:this.alarmfiles.pristationpersonconn,
          pristationconn:this.alarmfiles.pristationconn,
          conn_119:this.alarmfiles.conn_119,
          conn_120:this.alarmfiles.conn_120,
          conn_110:this.alarmfiles.conn_110,
          rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
          cancel:this.alarmfiles.cancel,
          signforflag:0,
          arriveflag:0,
          rescuendflag:0,
          stopflag:0,
          orderendflag:1,
          ordernum:this.alarmfiles.ordernum,
          flowstep:'40',
          maintainback: this.alarmfiles.maintainback,
          rescueback: this.alarmfiles.rescueback,
          endflag:'1',
          waitorder:'1',
          endding:'0'
        }
        if (this.alarmfiles.alarmid == '' || this.alarmfiles.alarmid == null) {
          addAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.show8=true;
              this.show9=true;
              this.rescuendflag=false;
              this.stopflag=false;
              this.orderendflag=true;
              this.$emit('change',true)
            }
          })
        } else {
          modifyAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.show8=true;
              this.show9=true;
              this.rescuendflag=false;
              this.stopflag=false;
              this.orderendflag=true;
              this.$emit('change',true)
            }
          })
        }
      }
    },
    //工单结束
    orderend(){
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"工单结束"+"(操作员:"+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
      if(this.alarmfiles.rescueprog==null){
        this.alarmfiles.rescueprog = record;
      }else{
        this.alarmfiles.rescueprog += record
      }

      let records=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"短信内容：地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，电梯发生(故障/报警)，当前工单已关闭。"+"短信已经发送"+"\n"
      if(this.alarmfiles.message==null){
        this.alarmfiles.message = records;
      }else{
        this.alarmfiles.message += records
      }
      if(this.alarmfiles.calledtime==null){
        this.$message.info("请选择接警时间")
      }else if(this.alarmfiles.maintenanceper1conn==null&&this.alarmfiles.maintenanceper2conn==null&&this.alarmfiles.areamanagerconn==null&&this.alarmfiles.depmanagerconn==null&&this.alarmfiles.maintenancedepconn==null){
        this.$message.info("请选择响应人员")
      } else if(this.alarmfiles.faultype==null){
        this.$message.info("请选择故障类型")
      }else if(this.alarmfiles.rescueres==null){
        this.$message.info("请选择救援结果")
      }else if(this.alarmfiles.backtype==null){
        this.$message.info("请选择回访方式")
      }else if(this.alarmfiles.arrtime==null){
        this.$message.info("请选择维保人员到达时间")
      }else if(this.alarmfiles.mainteva==null){
        this.$message.info("请评价维保人员")
      }else if(this.alarmfiles.miantcomeva==null){
        this.$message.info("请评价维保公司")
      }else if(this.alarmfiles.safeva==null){
        this.$message.info("请评价安全管理人员")
      }else if(this.alarmfiles.rescuexplain==null){
        this.$message.info("请说明救援情况")
      }else{
        let params = {
          alarmid: this.alarmfiles.alarmid,
          eventrecordid:this.eventRecordId[0],
          trappednum:this.alarmfiles.trappednum,
          alarmtype:this.alarmfiles.alarmtype,
          liftrescue:this.alarmfiles.liftrescue,
          adress:this.alarmfiles.adress,
          alarmcaller:this.alarmfiles.alarmcaller,
          callertel:this.alarmfiles.callertel,
          liftfault:this.alarmfiles.liftfault,
          calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
          pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          pristationcontime:this.alarmfiles.pristationcontime,
          rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
          rescueprog:this.alarmfiles.rescueprog,
          constime:this.constimes,
          prearrtime:this.alarmfiles.prearrtime,
          message:this.alarmfiles.message,
          disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          dispconstime:this.alarmfiles.dispconstime,
          resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          resperconstime:this.alarmfiles.resperconstime,
          savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescueconstime:this.alarmfiles.rescueconstime,
          lifteventype:this.alarmfiles.lifteventype,
          faultype:this.alarmfiles.faultype,
          secondfaultype:this.alarmfiles.secondfaultype,
          faultdesc:this.alarmfiles.faultdesc,
          rescuedby:this.alarmfiles.rescuedby,
          rescueres:this.alarmfiles.rescueres,
          rescuenum:this.alarmfiles.rescuenum,
          backtype:this.alarmfiles.backtype,
          arrtime:this.alarmfiles.arrtime,
          mainteva:this.alarmfiles.mainteva,
          miantcomeva:this.alarmfiles.miantcomeva,
          safeva:this.alarmfiles.safeva,
          rescuexplain:this.alarmfiles.rescuexplain,
          safeconn:this.alarmfiles.safeconn,
          userdepconn:this.alarmfiles.userdepconn,
          maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
          maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
          areamanagerconn:this.alarmfiles.areamanagerconn,
          depmanagerconn:this.alarmfiles.depmanagerconn,
          maintenancedepconn:this.alarmfiles.maintenancedepconn,
          stationperson1conn:this.alarmfiles.stationperson1conn,
          stationperson2conn:this.alarmfiles.stationperson2conn,
          pristationpersonconn:this.alarmfiles.pristationpersonconn,
          pristationconn:this.alarmfiles.pristationconn,
          conn_119:this.alarmfiles.conn_119,
          conn_120:this.alarmfiles.conn_120,
          conn_110:this.alarmfiles.conn_110,
          rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
          cancel:this.alarmfiles.cancel,
          signforflag:0,
          arriveflag:0,
          rescuendflag:0,
          stopflag:0,
          orderendflag:0,
          endflag:'2',
          ordernum:this.alarmfiles.ordernum,
          flowstep:'50',
          maintainback: this.alarmfiles.maintainback,
          rescueback: this.alarmfiles.rescueback,
          waitorder:'1',
          endding:'1'
        }
        if(this.alarmfiles.alarmid==''||this.alarmfiles.alarmid==null){
          addAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.orderendflag=false;
              this.saveflag=false;
              this.$emit('change',true)
            }
          })
        }else{
          modifyAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.orderendflag=false;
              this.saveflag=false;
              for(var i=0;i<this.callers.length;i++) {
                if (this.callers[i]) {
                  let param = {
                    userdepid:this.queryParams.userdepid,
                    maintenancedepid:this.queryParams.maintenancedepid,
                    areacode:this.areacode,
                    number: this.callers[i],
                    signName: this.signName,
                    templateCode: this.sendOrderend,
                    templateParam: {
                      Add: this.formDatas.adress,
                      resuceCode: this.formDatas.liftrescue,
                      time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    }
                  }
                  sendMessage(param)
                }
              }
              let params = {
                areacode:this.areacode,
              };
              findParamsListByConditions(params).then(res => {
                if (res && res.returncode == '0') {
                  if(res.item[0].rescueable==1&&res.item[0].alarmtofault==1){
                    let param={
                      devicenum:this.alarmfiles.devicenum,
                      eventcode:'100',
                      eventtype:1,
                      eventstarttime:this.alarmfiles.calledtime,
                      eventendtime:this.alarmfiles.savetime,
                      eventstatus: 2,
                    }
                    addEventrecord(param).then(res=>{
                      if(res&&res.returncode=='0'){
                        this.$message.success("当前工单已转为困人故障")
                      }
                    })
                  }
                }
              })
              this.$emit('change',true)
            }
          })
        }
      }
    },
    //自行脱困
    stop(){
      this.constimes=this.getConsutime(this.alarmfiles.calledtime,new Date())
      let record=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"被困人员已经自行脱困"+"(操作员:"+this.username+"，"+"坐席工号："+this.caller+")"+"\n"
      if(this.alarmfiles.rescueprog==null){
        this.alarmfiles.rescueprog = record;
      }else{
        this.alarmfiles.rescueprog += record
      }
      let records=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")+" "+"地址："+this.formDatas.adress+"，救援代码："+this.formDatas.liftrescue+"，电梯发生(故障/报警)，当前被困人员已自行脱困。"+"\n"
      if(this.alarmfiles.message==null){
        this.alarmfiles.message = records;
      }else{
        this.alarmfiles.message += records
      }
      let params = {
        alarmid: this.alarmfiles.alarmid,
        eventrecordid:this.eventRecordId[0],
        trappednum:this.alarmfiles.trappednum,
        alarmtype:this.alarmfiles.alarmtype,
        liftrescue:this.alarmfiles.liftrescue,
        adress:this.alarmfiles.adress,
        alarmcaller:this.alarmfiles.alarmcaller,
        callertel:this.alarmfiles.callertel,
        liftfault:this.alarmfiles.liftfault,
        calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
        pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        pristationcontime:this.alarmfiles.pristationcontime,
        rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
        rescueprog:this.alarmfiles.rescueprog,
        constime:this.constimes,
        prearrtime:this.alarmfiles.prearrtime,
        message:this.alarmfiles.message,
        disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        dispconstime:this.alarmfiles.dispconstime,
        resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        resperconstime:this.alarmfiles.resperconstime,
        savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
        rescueconstime:this.alarmfiles.rescueconstime,
        lifteventype:this.alarmfiles.lifteventype,
        faultype:this.alarmfiles.faultype,
        secondfaultype:this.alarmfiles.secondfaultype,
        faultdesc:this.alarmfiles.faultdesc,
        rescuedby:this.alarmfiles.rescuedby,
        rescueres:this.alarmfiles.rescueres,
        rescuenum:this.alarmfiles.rescuenum,
        backtype:this.alarmfiles.backtype,
        arrtime:this.alarmfiles.arrtime,
        mainteva:this.alarmfiles.mainteva,
        miantcomeva:this.alarmfiles.miantcomeva,
        safeva:this.alarmfiles.safeva,
        rescuexplain:this.alarmfiles.rescuexplain,
        safeconn:this.alarmfiles.safeconn,
        userdepconn:this.alarmfiles.userdepconn,
        maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
        maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
        areamanagerconn:this.alarmfiles.areamanagerconn,
        depmanagerconn:this.alarmfiles.depmanagerconn,
        maintenancedepconn:this.alarmfiles.maintenancedepconn,
        stationperson1conn:this.alarmfiles.stationperson1conn,
        stationperson2conn:this.alarmfiles.stationperson2conn,
        pristationpersonconn:this.alarmfiles.pristationpersonconn,
        pristationconn:this.alarmfiles.pristationconn,
        conn_119:this.alarmfiles.conn_119,
        conn_120:this.alarmfiles.conn_120,
        conn_110:this.alarmfiles.conn_110,
        rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
        cancel:this.alarmfiles.cancel,
        signforflag:0,
        arriveflag:0,
        rescuendflag:0,
        stopflag:0,
        orderendflag:0,
        endflag:'2',
        ordernum:this.alarmfiles.ordernum,
        flowstep:'50',
        maintainback: this.alarmfiles.maintainback,
        rescueback: this.alarmfiles.rescueback,
        waitorder:'1',
        endding:'1'
      }
      if(this.alarmfiles.alarmid==''||this.alarmfiles.alarmid==null){
        addAlarmFiles(params).then(res=>{
          if(res&&res.returncode=='0'){
            this.signforflag=false;
            this.arriveflag=false;
            this.rescuendflag=false;
            this.stopflag=false;
            this.orderendflag=false;
            this.saveflag=false;
            this.$emit('change',true)
          }
        })
      }else{
        modifyAlarmFiles(params).then(res=>{
          if(res&&res.returncode=='0'){
            this.signforflag=false;
            this.arriveflag=false;
            this.rescuendflag=false;
            this.stopflag=false;
            this.orderendflag=false;
            this.saveflag=false;
            this.$emit('change',true)
          }
        })
      }
    },
    //保存
    save(){
      this.constimes=moment(new Date()).format("YYYYMMDDHHmmss");
      let dateString1=this.constimes
      let h1=(moment(dateString1,"YYYYMMDDHHmmss").format("HH")-moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("HH"));
      let m1=(moment(dateString1,"YYYYMMDDHHmmss").format("mm")-moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("mm"))
      let s1=(moment(dateString1,"YYYYMMDDHHmmss").format("ss")-moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("ss"));
      if(s1<0){
        if(m1<0){
          h1=h1-1;
          m1=m1-1;
          m1=m1+60;
          s1=s1+60
        }else{
          m1=m1-1;
          s1=s1+60;
        }
      }else{
        if(m1<0){
          h1=h1-1;
          m1=m1+60;
        }
      }
      let str1;
      if(h1==0){
        if(m1==0){
          str1=s1+'秒'
        }else{
          str1=m1+'分'+s1+'秒'
        }
      }else if(s1>=0){
        str1=h1+'时'+m1+'分'+s1+'秒'
      }
      this.constimes=str1;
      if(this.alarmfiles.calledtime==null){
        this.$message.info("请选择接警时间")
      }else if(this.alarmfiles.maintenanceper1conn==null&&this.alarmfiles.maintenanceper2conn==null&&this.alarmfiles.areamanagerconn==null&&this.alarmfiles.depmanagerconn==null&&this.alarmfiles.maintenancedepconn==null){
        this.$message.info("请选择响应人员")
      }else{
        let params = {
          alarmid: this.alarmfiles.alarmid,
          eventrecordid:this.eventRecordId[0],
          trappednum:this.alarmfiles.trappednum,
          alarmtype:this.alarmfiles.alarmtype,
          liftrescue:this.alarmfiles.liftrescue,
          adress:this.alarmfiles.adress,
          alarmcaller:this.alarmfiles.alarmcaller,
          callertel:this.alarmfiles.callertel,
          liftfault:this.alarmfiles.liftfault,
          calledtime:this.alarmfiles.calledtime==null?null:moment(this.alarmfiles.calledtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedeptime:this.alarmfiles.maintenancedeptime==null?null:moment(this.alarmfiles.maintenancedeptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          maintenancedepcontime:this.alarmfiles.maintenancedepcontime,
          pristationtime:this.alarmfiles.pristationtime==null?null:moment(this.alarmfiles.pristationtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          pristationcontime:this.alarmfiles.pristationcontime,
          rescuespecialisttime:this.alarmfiles.rescuespecialisttime==null?null:moment(this.alarmfiles.rescuespecialisttime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescuespecialistscontime:this.alarmfiles.rescuespecialistscontime,
          rescueprog:this.alarmfiles.rescueprog,
          constime:this.constimes,
          prearrtime:this.alarmfiles.prearrtime,
          message:this.alarmfiles.message,
          disptime:this.alarmfiles.disptime==null?null:moment(this.alarmfiles.disptime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          dispconstime:this.alarmfiles.dispconstime,
          resperarrtime:this.alarmfiles.resperarrtime==null?null:moment(this.alarmfiles.resperarrtime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          resperconstime:this.alarmfiles.resperconstime,
          savetime:this.alarmfiles.savetime==null?null:moment(this.alarmfiles.savetime,"YYYYMMDDHHmmss").format("YYYYMMDDHHmmss"),
          rescueconstime:this.alarmfiles.rescueconstime,
          lifteventype:this.alarmfiles.lifteventype,
          faultype:this.alarmfiles.faultype,
          secondfaultype:this.alarmfiles.secondfaultype,
          faultdesc:this.alarmfiles.faultdesc,
          rescuedby:this.alarmfiles.rescuedby,
          rescueres:this.alarmfiles.rescueres,
          rescuenum:this.alarmfiles.rescuenum,
          backtype:this.alarmfiles.backtype,
          arrtime:this.alarmfiles.arrtime,
          mainteva:this.alarmfiles.mainteva,
          miantcomeva:this.alarmfiles.miantcomeva,
          safeva:this.alarmfiles.safeva,
          rescuexplain:this.alarmfiles.rescuexplain,
          safeconn:this.alarmfiles.safeconn,
          userdepconn:this.alarmfiles.userdepconn,
          maintenanceper1conn:this.alarmfiles.maintenanceper1conn,
          maintenanceper2conn:this.alarmfiles.maintenanceper2conn,
          areamanagerconn:this.alarmfiles.areamanagerconn,
          depmanagerconn:this.alarmfiles.depmanagerconn,
          maintenancedepconn:this.alarmfiles.maintenancedepconn,
          stationperson1conn:this.alarmfiles.stationperson1conn,
          stationperson2conn:this.alarmfiles.stationperson2conn,
          pristationpersonconn:this.alarmfiles.pristationpersonconn,
          pristationconn:this.alarmfiles.pristationconn,
          conn_119:this.alarmfiles.conn_119,
          conn_120:this.alarmfiles.conn_120,
          conn_110:this.alarmfiles.conn_110,
          rescuespecialistsconn:this.alarmfiles.rescuespecialistsconn,
          cancel:this.alarmfiles.cancel,
          signforflag:this.signforflag==true?this.signforflag=1:this.signforflag=0,
          arriveflag:this.arriveflag==true?this.arriveflag=1:this.arriveflag=0,
          rescuendflag:this.rescuendflag==true?this.rescuendflag=1:this.rescuendflag=0,
          stopflag:this.stopflag==true?this.stopflag=1:this.stopflag=0,
          orderendflag:this.orderendflag==true?this.orderendflag=1:this.orderendflag=0,
          ordernum:this.alarmfiles.ordernum,
          flowstep:'',
          maintainback: this.alarmfiles.maintainback,
          rescueback: this.alarmfiles.rescueback,
        }
        if(this.alarmfiles.alarmid==''||this.alarmfiles.alarmid==null){
          addAlarmFiles(params).then(res=>{
            this.$emit('change',true)
            this.close()
          })
        }else{
          modifyAlarmFiles(params).then(res=>{
            this.$emit('change',true)
            this.close()
          })
        }
      }
    },
    //关闭
    close(){
      this.show0=false
      this.pages = 'alarmMap'
      this.$emit('alarmMap')
    },
  }
}
</script>
<style lang="scss" scoped>
.bgcolor {
  background-color: #1890ff;
  color: white;
  border: 0;
  outline: none;
}
.alarmDetail{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
.monitor{
  float:right;
  //margin-left: 930px;
  margin-bottom: 2px;
}
.message{
  //margin-left: 930px;
  float:right;
  margin-bottom: 2px;
}
.form-in-fourcolumns {
  &.ant-form .ant-form-item {
    width: calc(25% - 32px);

    &.all-line {
      width: auto;
      min-width: calc(25% - 32px);
    }

    &.showellipse {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.save{
  margin-left: 30%;
}
.form-in-threecolumns {
  &.ant-form .ant-form-item {
    width: calc(33.3% - 16px);
    &.all-line {
      width: auto;
      min-width: calc(33.33% - 16px);
    }
    &.showellipse {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .ant-form-item-label, .ant-form-item-control {
    line-height: 28px;
  }
}
</style>