<template>
  <div class="box-container">
    <a-modal v-model="modalVisible" style="margin-top: 10px;" width="50%">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">选择设备</h3>
        </div>
      </div>
      <div class="box-container-inner">
      <div class="simple-query">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
          <a-form-model-item label="电梯注册码" prop="liftnum">
            <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="救援码" prop="liftrescue">
            <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="地址" prop="adress">
            <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
          <div v-show="showAdvanced" class="advanced-query">
            <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form" style="margin-left: 5%">
              <a-form-model-item label="地址" prop="adress">
                <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="区域" prop="areacode">
                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
              </a-form-model-item>
            </a-form-model>
          </div>
        <div class="query-btns" style="text-align: center">
          <a-button @click="resetQueryParams">重置</a-button>
          <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--          <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
        </div>
      </div>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner">
<!--          <div class="table-header">-->
<!--            <div class="table-btns">-->
<!--              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>-->
<!--            </div>-->
<!--          </div>-->
          <a-table :row-selection="{onChange: rowSelectChange,type : 'radio'}" :columns="tableColumns" :data-source="tableData" row-key="liftid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {getLiftinfoListByCondition} from "A/jcgn";
export default {
  mixins: [areaselect, deptselect, pagination],
  name: "liftSelect",
  props: {
    visible: {
      default: false
    },
    LiftData:{
      default: null
    }
  },
  data(){
    return{
      queryParams: {
      },
      modalVisible: false,
      showAdvanced: false,
      tableData: [],
      tableLoading: false,
      tableColumns: [
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
          width:100
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
      ],
      queryAreaSelected: [],
      counts:'',
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods:{
    init() {
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchliftids = selectedRowKeys.join('|');
    },
    confirm(){
      this.$emit('upDate',this.batchliftids)
      this.modalVisible=false;
    },
  },
}
</script>

<style scoped>

</style>