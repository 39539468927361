<template>
  <div class="box-container">
    <a-modal v-model="modalVisible" style="margin-top: 10px;" width="850px" height="500px">
      <template slot="footer">
        <a-button @click="modalVisible=false">退出</a-button>
      </template>
      <div class="demo" v-if="modalVisible">
<!--        <video-->
<!--            id="videoId"-->
<!--            controls = "true"-->
<!--            display="block">-->
<!--        </video>-->
        <LivePlayer
            id="liveplayer"
            :videoUrl="videoUrl"
            fluent
            live
            autoplay
            alt=""
            aspect='fullscreen'
            :show-custom-button="false"
            :hide-snapshot-button="true"
        ></LivePlayer>
      </div>
    </a-modal>
  </div>
</template>
<script>
import LivePlayer from '@liveqing/liveplayer'
import {setPlayerParams} from "A/xtpz";
export default {
  name: "alarmVideo",
  components: {
    LivePlayer
  },
  props: {
    visible: {
      default: false
    },
    VideoDevice:{
      default: null
    }
  },
  data() {
    return {
      modalVisible: false,
      flvPlayer:null,
      videoUrl:'http://',
      videoElement:'',
      start_time:'',
      end_time:'',
      playback_ssrc:'',
      play_flag:1,
    }
  },
  watch:{
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  destroyed() {
    // this.flv_destroy()
  },
  mounted() {
    this.preview();
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods:{
    // initPlayer(){
    //   if (flvjs.isSupported()) {
    //     this.videoElement = document.getElementById('videoId');
    //     this.flvPlayer = flvjs.createPlayer({
    //       type: 'flv',
    //       url: this.videoUrl
    //     });
    //     this.flvPlayer.attachMediaElement(this.videoElement);
    //     this.flvPlayer.load();
    //     this.flvPlayer.play();
    //   }
    // },
    preview(){
      let params = {
        devicenum: this.VideoDevice,
        data: [
          {
            params: {
              methord: "PUT",
              url:"/video",
              body: {
                ssrc:this.VideoDevice+'-0',
                playtype:1,
                start_time:this.start_time,
                end_time:this.end_time,
              }
            }
          }
        ]
      }
      var that=this;
      setPlayerParams(params).then(res=>{
        if(res&&res.returncode=='0') {
          this.videoUrl = res.url
          // setTimeout(function () {
          //   that.videoUrl = res.url
          //   that.initPlayer()
          // }, 2000);
        }
      })
    },
    flv_destroy() {
      console.log("注销播放器组件")
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
      this.flvPlayer = null
    },
  },
}
</script>

<style scoped>
.demo{
  display: inline-block;
  width: 785px;
  height: 441px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}

.demo:hover{
  display: block;
}
</style>