<!--应急救援模块主页面-->
<template>
  <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
    <div class="bg-white">
      <div class="main-container" style="margin-top: -20px">
        <div class="title">应急救援</div>
      </div>
    </div>
    <div class="main-container">
      <div class="left-tabs">
        <div class="table-header">
          <h3 class="table-title">报警信息</h3>
          <div class="table-btns">
            <a-button type="danger"  @click="alarm" :disabled="isAble||alarmAble">救援接警</a-button>
            <a-button type="primary"  @click="alarmMap">应急地图</a-button>
          </div>
        </div>
        <div class="box-inner">
          <div class="">
              <a-button type="link" @click="showAdvanced=!showAdvanced" style="margin-left: -15%;margin-bottom: -5%;margin-top: 3%">高级查询<a-icon :type="'search'"></a-icon></a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="update"></a-icon>
          </div>
          <div v-show="showAdvanced" class="advanced-query">
            <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="form-in-twocolumns" >
              <a-form-model-item label="电梯注册码" prop="liftnum">
                <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
                <a-input v-model.trim="queryParams.traceabilitynum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯救援码" prop="liftrescue">
                <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="安装地址" prop="adress">
                <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="工单编号" prop="ordernum">
                <a-input v-model.trim="queryParams.ordernum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="起始时间" prop="installendtime">
                <a-range-picker v-model="queryInstallDate"></a-range-picker>
              </a-form-model-item>
            </a-form-model>
            <div class="showbutton">
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button @click="resetQueryParams">重置</a-button>
            </div>
          </div>
        </div>
        <div class="box-container" style="margin-top: 10px;">
          <a-table :columns="tableColumns" :data-source="tableData" :customRow="rowClick" row-key="alarmid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="flowstep" slot-scope="value">
            <a-tooltip :title="flowstepMap[value]">
              <span>{{flowstepMap[value]}}</span>
            </a-tooltip>
          </span>
            <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.alarmid" :disabled="isAble||alarmAble">详情</a-menu-item>
                <a-menu-item :key="'revocation-'+record.alarmid" :disabled="isAble||alarmAble">撤销</a-menu-item>
<!--                <a-menu-item :key="'location-'+record.alarmid">定位</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
      <div class="right-tabs" v-if="showright">
        <alarm-map v-if="pages=='alarmMap'" ref="alarmMap"></alarm-map>
<!--        :MapDataX="mapDatax" :MapDataY="mapDatay"-->
        <alarm @alarmDetail="pages='alarmDetail'" @alarmMap="pages='alarmMap'" :PhoneData="opcall" @alarmData="unitid=this.userdepid" v-if="pages=='alarm'" ref="alarm"></alarm>
        <alarm-detail v-if="pages=='alarmDetail'" ref="alarmDetail" @alarm="pages='alarm'" @alarmMap="pages='alarmMap'" @change="change" :child="states" :AlarmId="alarmid" :PhoneData="opcall"></alarm-detail>
<!--        @alarmData="unitid=this.userdepid"-->
      </div>
    </div>
  </page-container>
</template>
<script>
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import alarmDetail from "V/yjcz/emergency/alarmDetail";
import alarmMap from "V/yjcz/emergency/alarmMap";
import alarm from "V/yjcz/emergency/alarm";
import {mapState} from "vuex";
import moment from 'moment'
import {getAlarmFilesListByCondition, modifyAlarmFiles} from "A/thjlcx";
import {getLiftinfoListByCondition} from "A/jcgn";
import "A/jquery-1.7.1.min.js"
import "A/agentuc.js"
import Bus from "V/yjcz/emergency/bus";
import callCenter from "C/modals/callCenter";
import {findParamsListByCondition} from "A/xtpz";
export default {
  name: 'emergency',
  mixins: [ areaselect, deptselect,pagination],
  props: ['pageType'],
  components: {
    alarm,
    alarmMap,
    alarmDetail,
  },
  data() {
    return {
      states:'',
      timer:null,
      timers:null,
      moment,
      showright:true,
      alarmTime:'',
      mapDatax:'',
      mapDatay:'',
      // unitid:'',
      usertype:'',
      alarmid:'',
      // alarmData:'',
      eventstatus:'1',
      pages:'alarmMap',
      showAdvanced: false,
      ringVisible:false,
      caller:'',
      breadcrumb: [
        {
          name: '应急处置',
          path: ''
        },
        {
          name: '应急救援',
          path: ''
        },
      ],
      queryParams: {
        liftnum: '',
        liftrescue: '',
        adress: '',
        userdepname:'',
        maintenancedepname:'',
        traceabilitynum:'',
        ordernum:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      tableData: [],
      tableLoading: false,
      counts:'',

      //工单状态
      flowstepMap: {
        '10': '待签收',
        '20': '已签收,待抵达现场',
        '30': '抵达现场,待救援',
        '40': '救援完成,待评价',
        '50': '已完成',
        '60': '误报',
      },

      //通话
      phoneLoginVisible:true,
      isAble:false,
      alarmAble:true,
      opcall: '',
      msg_div: '',
      user: '',
      password: '',
      login_ext_no: '',
      login_queue: '',
      stat_div: '',
      dia_num: '',

    }
  },
  destroyed: function () {
    this.$router.push({
      params:{}
    })
    let newQuery = JSON.parse(JSON.stringify(this.$route.query))
    delete newQuery.caller //再删除page
    this.$router.replace({ query: newQuery })
  },
  computed: {
    ...mapState({
      eventRecordId: state => state.main.eventRecordId
    }),
    tableColumns(){
      return [
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          width:60,
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          width: 80,
          ellipsis: true,
        },
        {
          title: '报警时间',
          dataIndex: 'calledtime',
          key: 'calledtime',
          ellipsis: true,
          scopedSlots: { customRender: 'time' }
        },
        {
          title: '当前状态',
          dataIndex: 'flowstep',
          key: 'flowstep',
          ellipsis: true,
          scopedSlots: { customRender: 'flowstep' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 50,
          scopedSlots: {customRender: 'operation'}
        }
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    'states':{
      handler: function() {
        if (this.states) {
          this.update()
          this.states=false
        }
      }
    }
  },
  mounted() {
    if(this.usertype==6){
      console.log("执行应急工单刷新程序")
      const timer = setInterval(()=>{this.update();}, 60000);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
      })
    }
  },
  created() {
    this.init();
    this.getTableData(true);
  },
  beforeDestroy () { // 在组件销毁之前清除自定义事件
    Bus.$off('caller-value');
    this.caller=''
    // clearInterval(this.timer) // 清除定时器
    // this.timer = null;
  },
  methods: {
    change(val){
      this.states = val;
    },
    update(){
      if(sessionStorage.getItem("phoneLoginVisible")=='false'){
        this.phoneLoginVisible=false
        this.isAble=false
      }else{
        this.phoneLoginVisible=true
        this.isAble=true
      }
      this.getTableData()
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    init() {
      this.ringVisible=false;
      this.caller=this.$route.query.caller
      if(this.$route.query.caller){
        this.moveAlarmPage(this.caller)
      }
      this.alarmModal()
      // else{
      //   this.alarmModal();
      // }
      if(sessionStorage.getItem("phoneLoginVisible")=='false'){
        this.phoneLoginVisible=false
        this.isAble=false
      }else{
        this.phoneLoginVisible=true
        this.isAble=true
      }
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.areacode=logininfo.areacode
        this.user=logininfo.phonename
        this.password=logininfo.phonepassword
        this.login_ext_no=logininfo.phonextnum
        this.usertype=logininfo.usertype
      }
      this.getAlarmable()
    },
    getAlarmable(){
      let params={
        areacode:this.areacode
      }
      findParamsListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item[0].rescueable=='1'){
            this.alarmAble=false
          }
        }
      })
    },
    moveAlarmPage(caller){
      if(caller){
        this.pages = 'alarm'
        this.$emit('alarm')
        Bus.$emit('caller-value',caller)
        setTimeout(()=> {
          Bus.$emit('caller-value',caller)
        },2500)
      }
    },

    alarmModal(){
      var that=this;
      callCenter.methods.bind_event(callCenter.methods.initPhone())
      callCenter.methods.initPhone().lib.event("ring").unsubscribe().subscribe(function (ext) {
        console.log(that.pages)
        if(ext.agent_state=='Receiving'&&that.pages!='alarmDetail'){
          that.ringVisible=true;
          that.caller=ext.peer;
          Bus.$emit('caller-value',ext.peer)
          setTimeout(()=> {
            Bus.$emit('caller-value',ext.peer)
          },2500)
          that.show0=false
          that.pages = 'alarm'
          that.$emit('alarm')
        }
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#hangup").attr("disabled", false);
        $("#answer").attr("disabled", false);
        $("#spy").attr("disabled", true);
        $("#breakin").attr("disabled", true);
        $("#breakin2threeway").attr("disabled", true);
        $("#blind_transfer").attr("disabled", false);
        console.log("电话振铃了[此处完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
      });
    },

    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        endding:'0',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getAlarmFilesListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    alarm(){
      this.pages='alarm'
    },
    alarmMap(){
      this.pages='alarmMap'
    },
    rowClick(record, index) {
      return {
        on: {
          dblclick: () => {
            let params = {
              liftrescue: record.liftrescue
            }
            getLiftinfoListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.mapDatax = res.item[0].coordinatex
                this.mapDatay = res.item[0].coordinatey
                this.liftrescue=res.item[0].liftrescue
                this.$refs.alarmMap.location(this.liftrescue)
              }
            })
          }
        }
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let alarmid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'alarmid', alarmid);
      if(type=='detail'){
        this.demap();
        setTimeout(()=> {
          this.detail('detail',record)
        }, 100);
        // this.detail('detail',record)
      }else if(type=='revocation'){
        this.revocation('revocation',record)
      }else if(type=='location'){
        this.location('location',record)
      }
    },
    revocation(value,record){
      let param = {
        alarmid:record.alarmid,
      }
      getAlarmFilesListByCondition(param).then(res=>{
        if(res&&res.returncode=='0'){
          let params = {
            alarmid:res.item[0].alarmid,
            cancel:1,
            waitorder:'1',
            endding: '1',
            endflag:'2',
          }
          modifyAlarmFiles(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.$message.success("撤销成功")
              this.getTableAlarm(true)
            }
          })
        }
      })
    },
    location(value,record) {
      let params = {
        liftrescue: record.liftrescue
      }
      getLiftinfoListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.mapDatax = res.item[0].coordinatex
          this.mapDatay = res.item[0].coordinatey
        }
      })
    },
    demap(){
      this.$emit('alarmMao')
      this.pages = 'alarmMap'
    },
    detail(value,record){
      this.alarmid=record.alarmid
      // this.$store.commit('main/setEventRecordId', [record.eventrecordid]);
      // this.$store.commit('main/setCodeRescue', [record.liftrescue]);
      this.$emit('alarmDetail')
      this.pages = 'alarmDetail'
    },
  }
}
</script>
<style lang="scss" scoped>
.bg-white {
  background-color: #fff;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
  .right-tabs{
    flex-grow: 1;
    margin-left: 10px;
    //overflow: auto;
    width: 67%;
    min-height: calc(100vh - 200px);
    float: right;
    margin-left: 0.5%;
    padding-top: 1%;
    background-color: white;
    border-radius: 5px;
  }
  .left-tabs {
    flex-shrink: 0;
    width: 33%;
    //min-height: calc(100vh - 200px);
    //height: auto;
    padding-top: 1.0%;
    background-color: white;
    border-radius: 5px;
    .table-header{
      .table-title {
        float: left;
        margin-left: 2.0%;
        line-height: 32px;
    }
      .table-btns {
        float: right;
        margin-right: 4%;
      }
    }
    .box-inner{
      margin-top: 5%;
      .query-btns {
        float: left;
        margin-left: -1%;
        width: 100%;
        //text-align: left;
      }
      .advanced-query{
        margin-left: 3%;
        .simple-query {
          //display: flex;
          float: left;
          margin-left: 10px;
        }
        .query-form {
          &.ant-form-inline .ant-form-item {
            margin-left: 10px;
            width: 45%;
          }
          &.ant-form-inline .ant-form-item > .ant-form-item-label {
            width: 45%;
          }
          &.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
            width: 55%;
          }
        }
        .showbutton{
          margin-top: 5%;
          margin-left: 35%;
        }
      }
    }
    .box-container{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>