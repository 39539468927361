<template>
  <div class="main-container" v-if="show0">
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :rules="formRules" :model="queryForms"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <div class="card-content">
        <h3 style="font-weight: bold; color: #333;">报警添加</h3>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;">
          <span style="margin-left: 2%">受理信息</span>
        </h3>
        <a-form-model-item label="电梯救援码" prop="liftrescue">
          <a-input  v-model="queryForms.liftrescue" placeholder="" style="width:100%;margin-top: -100px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="" prop="callertel">
          <a-button type="primary" @click="liftSelect">选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="报警类型" prop="alarmtype">
          <a-input  v-model="queryForms.alarmtype" placeholder="请输入" style="width:100%;margin-top: -100px" read-only="read-only"></a-input>
        </a-form-model-item>
        <a-form-model-item label="来电号码" prop="callertel">
          <a-input v-model="queryForms.callertel" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="报警人员" prop="alarmcaller">
          <a-input v-model="queryForms.alarmcaller" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="受理人员" prop="username">
          <a-input v-model="username" placeholder="请输入" read-only="read-only"></a-input>
        </a-form-model-item>
        <a-form-model-item label="受理时间" prop="calledtime">
          <a-date-picker
              style="width:100%"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
              format="YYYY-MM-DD HH:mm:ss"
              v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
              :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              placeholder="请选择时间"
              @change="onChange"/>
        </a-form-model-item>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
          <span style="margin-left: 2%">备注</span>
        </h3>
        <a-form-model-item prop="message" style="width: 48%;">
          <textarea v-model="queryForms.message" style="width:300%;height:150px;margin-left: 35%" placeholder="请输入"></textarea>
        </a-form-model-item>
        <div class="showbutton" style="text-align: center">
          <a-button type="primary"  @click="save()">保存</a-button>
          <a-button @click="exit">关闭</a-button>
        </div>
      </div>
    </a-form-model>
    <liftSelect :visible.sync="liftselectVisible" ref="liftselect" :LiftData="liftData" @upDate="change"></liftSelect>
  </div>
</template>

<script>
import moment from "moment";
import {getCache} from "U/index";
import {addAlarmessage, addAlarmFiles} from "A/thjlcx";
import liftSelect from "V/yjcz/emergency/liftSelect";
import {getLiftinfoById} from "A/jcgn";

export default {
  name: "alarmConsult",
  components: {
    liftSelect
  },
  props: {
    AlarmConsult:{
      default: null
    },
  },
  data() {
    return {
      moment,
      pages: '',
      show0:true,
      uesrname:'',
      alarmtype:'',
      liftData:'',
      liftselectVisible:false,
      queryForms:{
        alarmtype:'',
        alarmcaller:'',
        liftrescue:'',
        callertel:'',
        calledtime:'',
        message:'',
      },
      formRules: {
        liftrescue: [{required: true, message: '请选择电梯' ,trigger:'blur,change'}],
        calledtime: [{required: true, message: '请选择受理时间',trigger:'blur,change'}],
      },
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.initData();
    },
    initData(){
      if(this.AlarmConsult==3){
        this.queryForms.alarmtype="咨询"
      }else if(this.AlarmConsult==4){
        this.queryForms.alarmtype="打错电话"
      }else if(this.AlarmConsult==5){
        this.queryForms.alarmtype="恶意骚扰"
      } else if(this.AlarmConsult==6){
        this.queryForms.alarmtype="其他"
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    liftSelect(){
      this.liftselectVisible=true;
    },
    onChange (value, dateString) {
      this.queryForms.calledtime=dateString
    },
    save(){
      console.log(this.queryForms.liftrescue)
      console.log(this.queryForms.calledtime)
      if(this.queryForms.liftrescue==null||this.queryForms.liftrescue==""||this.queryForms.calledtime==null||this.queryForms.calledtime==""){
        this.$message.info("请填写完整信息")
      }else {
        let params = {
          eventrecordid: '',
          liftrescue: this.queryForms.liftrescue,
          alarmtype: this.AlarmConsult,
          adress: this.queryForms.adress,
          trappednum: this.queryForms.trappednum,
          alarmcaller: this.queryForms.alarmcaller,
          callertel: this.queryForms.callertel,
          liftfault: this.queryForms.liftfault,
          calledtime: moment(this.queryForms.calledtime).format("YYYYMMDDHHmmss"),
          maintenancedeptime: '',
          maintenancedepcontime: '',
          pristationtime: '',
          pristationcontime: '',
          rescuespecialisttime: '',
          rescuespecialistscontime: '',
          rescueprog: '',
          constime: '',
          prearrtime: '',
          message: this.queryForms.message,
          disptime: '',
          dispconstime: '',
          resperarrtime: '',
          resperconstime: '',
          savetime: '',
          rescueconstime: '',
          lifteventype: '',
          faultype: '',
          secondfaultype: '',
          faultdesc: '',
          rescuedby: '',
          rescueres: '',
          rescuenum: '',
          backtype: '',
          arrtime: '',
          mainteva: '',
          miantcomeva: '',
          safeva: '',
          rescuexplain: '',
          safeconn: '',
          userdepconn: '',
          maintenanceper1conn: '',
          maintenanceper2conn: '',
          areamanagerconn: '',
          depmanagerconn: '',
          maintenancedepconn: '',
          stationperson1conn: '',
          stationperson2conn: '',
          pristationpersonconn: '',
          pristationconn: '',
          conn_119: '',
          conn_120: '',
          conn_110: '',
          rescuespecialistsconn: '',
          cancel: '0',
          signforflag: 0,
          arriveflag: 0,
          rescuendflag: 0,
          stopflag: 0,
          orderendflag: 0,
          ordernum: '',
          endflag: '2',
          flowstep: '',
          rescueback: '',
          miantainback: '',
          liftid: this.queryForms.liftid,
          processperson: this.username,
          waitorder: '1',
          endding: '1',
          ordertype: '1'
        }
        addAlarmFiles(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success("保存成功")
            this.exit();
          }
        })
      }
    },
    exit() {
      this.show0=false
      // this.pages='alarmMap'
      this.$emit('alarm',this.show=true)
    },
    change(msg){
      let params = {
        liftid:msg
      }
      getLiftinfoById(params).then(res=>{
        if(res && res.returncode=='0'){
          this.queryForms.liftrescue=res.item.liftrescue
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  padding-bottom: 15px;
  padding-left: 15px;
}
</style>