<template>
  <div class="info-card">
  <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}" v-if="show">
    <div class="card-content">
      <h3 style="font-weight: bold; color: #333;">报警信息</h3>
      <a-form-model-item label="电梯救援码" prop="liftrescue">
        <div style="float: left">
          <a-input  v-model.trim="formDatas.liftrescue" placeholder="请输入" style="width:66%;"></a-input>
          <a-button type="primary" @click="checkLift" style="margin-left: 2.5%">查询</a-button>
        </div>

      </a-form-model-item>
      <a-form-model-item label="电梯位置" prop="adress">
        <a-input v-model.trim="formDatas.adress" placeholder="" read-only="read-only"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属区域" prop="areaname">
        <a-input v-model="formDatas.areaname" placeholder="" read-only="read-only"></a-input>
      </a-form-model-item>
      <a-form-model-item label="报警类别" prop="alarmtype" >
        <a-select placeholder="请选择" v-model="queryForms.alarmtype">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="0">困人</a-select-option>
          <a-select-option value="1">故障</a-select-option>
          <a-select-option value="2">人员伤亡</a-select-option>
          <a-select-option value="3">投诉</a-select-option>
          <a-select-option value="4">咨询</a-select-option>
          <a-select-option value="5">打错电话</a-select-option>
          <a-select-option value="6">恶意骚扰</a-select-option>
          <a-select-option value="7">其他</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="被困人数" prop="trappednum">
        <a-input v-model="queryForms.trappednum" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="呼叫人" prop="alarmcaller">
        <a-input v-model="queryForms.alarmcaller" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="呼叫电话" prop="callertel">
        <a-input v-model="callertel" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="未处理来电" prop="unhandled-call">
        <a-select v-model="phonenum" placeholder="请选择" @change="onChange">
          <a-select-option v-for="item in items" :key="id" :value="item.phone">{{item.phone}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电梯问题" prop="liftfault">
        <a-textarea v-model="queryForms.liftfault" placeholder="请输入"></a-textarea>
      </a-form-model-item>
      <div class="showbutton">
        <a-button type="primary"  @click="nextStep()">下一步</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
      <a-form-model ref="formDates" :model="formDatas" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
    <a-form-model-item label="救援码" prop="liftrescue">
      <a-input v-model.trim="formDatas.liftrescue" placeholder="请输入"></a-input>
    </a-form-model-item>
    <a-form-model-item label="使用单位" prop="userdepname">
      <a-input v-model.trim="formDatas.userdepname" placeholder="请输入"></a-input>
    </a-form-model-item>
      <div v-show="showAdvanced" class="advanced-query">
        <a-form-model-item label="电梯注册码" prop="liftnum">
          <a-input v-model.trim="formDatas.liftnum" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
          <a-input v-model.trim="formDatas.traceabilitynum" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="电梯位置" prop="adress">
          <a-input v-model.trim="formDatas.adress" placeholder="请输入"></a-input>
        </a-form-model-item>
      </div>
      <div class="checkbutton">
        <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
        <a-button @click="resetQueryParams">重置</a-button>
        <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
      </div>
      </a-form-model>
  </a-form-model>
    <div class="box-container" style="margin-top: 10px;"  v-if="show">
      <a-table :row-selection="{onChange: rowSelectChange,type : 'radio',}" :columns="tableColumns" :data-source="tableData" row-key="liftid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <div class="right-tabs">
      <alarm v-if="pages=='alarm'" ref="alarm"></alarm>
      <alarm-complaint v-if="pages=='alarmComplaint'" ref="alarmComplaint" @alarm="pages='alarm'"></alarm-complaint>
      <alarm-consult v-if="pages=='alarmConsult'" ref="alarmConsult" :AlarmConsult="alarmconsult" @alarm="pages='alarm'"></alarm-consult>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import {getLiftinfoById, getLiftinfoListByCondition} from "A/jcgn";
import {mapGetters, mapState} from "vuex";
import alarmMap from "V/yjcz/emergency/alarmMap";
import alarmComplaint from "V/yjcz/emergency/alarmComplaint";
import alarmConsult from "V/yjcz/emergency/alarmConsult";
import {
  addAlarmFiles,
  addEventrecord,
  deleteUntreatedCall,
  getAlarmFilesListByCondition,
  getUntreatedCallListByCondition
} from "A/thjlcx";
import {getEventrecordListByCondition} from "A/wlw";
import alarmDetail from "V/yjcz/emergency/alarmDetail";
import Bus from "V/yjcz/emergency/bus";
export default {
  name: "alarm",
  mixins: [areaselect, deptselect, pagination],
  // props: ['liftid']
  props:{
    PhoneData:{
      default: null
    },
  },
  components: {
    alarmMap,
    alarmComplaint,
    alarmConsult,
    alarmDetail
  },
  data() {
    return {
      moment,
      id:"id",
      show:true,
      pages: '',
      unitid:'',
      alarmconsult:'',
      showAdvanced: false,
      queryForms: {
        alarmid:'',
        trappednum:'',
        alarmtype:'',
        alarmcaller:'',
        callertel:'',
        liftfault:'',
        calledtime:new Date(),
      },
      callertel:'',
      formDatas: {
        liftnum: '',
        liftrescue: '',
        adress: '',
        userdepid:'',
        userdepname:'',
        maintenancedepname:'',
        areaname:'',
        devicenum:'',
        liftid:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      tableData: [],
      tableLoading: false,
      batchrowids: '',

      phonenum:'',
      items: [],
      //必填字段
      formRules: {
        liftrescue: [{required: true, message: '请输入电梯救援码',trigger:'blur,change'}],
        alarmtype: [{required: true, message: '请选择报警类型',trigger:'blur,change'}],
      }
    }
  },
  computed: {
      ...mapState({
        unitId: state => state.main.unitId,
        codeRescue: state => state.main.codeRescue,
      }),
    tableColumns() {
      return [
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        }, {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
      ]
    },
  },
  watch:{
    'queryForms.alarmtype':function (newVal){
      if(newVal==3){
        this.pages='alarmComplaint'
        this.show=false
      }else if(newVal==4||newVal==5||newVal==6||newVal==7){
        this.alarmconsult=newVal-1
        this.pages='alarmConsult'
        this.show=false
      }
    },
  },
  mounted:function(){
    var that=this;
    Bus.$on('caller-value',(e) => {
      // console.log(e)
      that.callertel=e
    })
  },
  destroyed: function () {
    Bus.$off('caller-value');
  },
  beforeDestroy () {
    // 销毁监听事件
    Bus.$off('caller-value');
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.getUntreatedCall();
    },
    onChange(){
      this.callertel=this.phonenum
    },
    getUntreatedCall() {
      let params ={
        phonenum:'',
        calltype:'',
        calltime:'',
      }
      getUntreatedCallListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          console.log(res.item)
          for(var i=0;i<res.item.length;i++){
            this.items.push({id:i,phone:res.item[i].phonenum})
          }
        console.log(this.items)
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.formDatas,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          console.log(res)
          this.tableData = res.item;
          this.pagination.total = res.count;
          // this.userdepid=res.item[0].userdepid
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    checkLift(){
      let params = {
        ...this.formDatas,
      };
      getLiftinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          let params ={
            liftid:res.item[0].liftid
          }
          getLiftinfoById(params).then(res=>{
            if(res&&res.returncode=='0'){
              console.log(res)
              this.formDatas.liftid=res.item.liftid
              this.formDatas.liftrescue=res.item.liftrescue
              this.formDatas.adress=res.item.adress
              this.formDatas.areaname=res.item.areaname
              this.formDatas.devicenum=res.item.deviceinfos[0].devicenum
            }
          })
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getLiftrescue() {
      let params = {
        ...this.formDatas,
        liftrescue: this.formDatas.liftrescue,
      };
      getLiftinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.unitid=res.item[0].userdepid
          // console.log(this.userdepid)
        }
      }).catch(err => {
      })
    },
    resetQueryParams() {
      this.$refs.formDates.resetFields();
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
      let params ={
        liftid:selectedRowKeys[0]
      }
      getLiftinfoById(params).then(res=>{
        if(res&&res.returncode=='0'){
          console.log(res)
          this.formDatas.liftid=res.item.liftid
          this.formDatas.liftrescue=res.item.liftrescue
          this.formDatas.adress=res.item.adress
          this.formDatas.areaname=res.item.areaname
          this.formDatas.devicenum=res.item.deviceinfos[0].devicenum
        }
      })
    },
    nextStep() {
      Bus.$off('caller-value');
      if(this.formDatas.liftrescue==null){
        this.$message.info("请输入电梯救援码")
      }else if(this.queryForms.alarmtype==''){
        this.$message.info("请选择报警类型")
      }else {
        let params = {
          eventrecordid: '',
          liftrescue: this.formDatas.liftrescue,
          alarmtype: this.queryForms.alarmtype,
          adress: this.formDatas.adress,
          trappednum: this.queryForms.trappednum,
          alarmcaller: this.queryForms.alarmcaller,
          callertel: this.callertel,
          liftfault: this.queryForms.liftfault,
          calledtime: moment(this.queryForms.calledtime).format("YYYYMMDDHHmmss"),
          maintenancedeptime: '',
          maintenancedepcontime: '',
          pristationtime: '',
          pristationcontime: '',
          rescuespecialisttime: '',
          rescuespecialistscontime: '',
          rescueprog: '',
          constime: '',
          prearrtime: '',
          message: '',
          disptime: '',
          dispconstime: '',
          resperarrtime: '',
          resperconstime: '',
          savetime: '',
          rescueconstime: '',
          lifteventype: '',
          faultype: '',
          secondfaultype: '',
          faultdesc: '',
          rescuedby: '',
          rescueres: '',
          rescuenum: '',
          backtype: '',
          arrtime: '',
          mainteva: '',
          miantcomeva: '',
          safeva: '',
          rescuexplain: '',
          safeconn: '',
          userdepconn: '',
          maintenanceper1conn: '',
          maintenanceper2conn: '',
          areamanagerconn: '',
          depmanagerconn: '',
          maintenancedepconn: '',
          stationperson1conn: '',
          stationperson2conn: '',
          pristationpersonconn: '',
          pristationconn: '',
          conn_119: '',
          conn_120: '',
          conn_110: '',
          rescuespecialistsconn: '',
          cancel: '0',
          signforflag: 1,
          arriveflag: 0,
          rescuendflag: 0,
          stopflag: 1,
          orderendflag: 0,
          ordernum: "RS" + moment(new Date).format("YYYYMMDDHHmmss")+ Math.round(Math.random()*9)+ Math.round(Math.random()*5)+ Math.round(Math.random()*5)+ Math.round(Math.random()*5),
          endflag: '0',
          flowstep: '10',
          rescueback: '',
          miantainback: '',
          liftid: this.formDatas.liftid,
          waitorder: '0',
          endding:'0',
          ordertype:'1',
        }
        addAlarmFiles(params).then(res => {
          if (res && res.returncode == '0') {
            let params={
              phonenum:this.callertel,
            }
            deleteUntreatedCall(params)
            Bus.$off('caller-value');
            this.pages = 'alarmDetail'
            this.$emit('alarmDetail')
          }else{
            this.$message.error(res.errormsg)
          }
        })
      }
    },
    cancel() {
      this.$emit('alarmMap')
      this.pages = 'alarmMap'
    },
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  padding-bottom: 15px;
  padding-left: 15px;
}
.showbutton{
  margin-top: 20px;
  margin-left: 47%;
}
.checkbutton{
  margin-top: 20px;
  margin-left: 48.5%;
}
.advanced-query {
}
.twocolumns{
  margin-left: 14.7%;
}
</style>