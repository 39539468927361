<template>
  <div class="find-container" ref="findContainer">
    <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" style="text-align: center">
      <a-form-model-item label="电梯注册码" prop="liftnum">
        <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="救援码" prop="liftrescue">
        <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="安装地址" prop="adress">
        <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="区域" prop="areacode">
        <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
      </a-form-model-item>
      <div class="query-btns" style="text-align: center">
        <a-button @click="resetQueryParams">重置</a-button>
        <a-button type="primary" class="after-btn" @click="getLoaction">查询</a-button>
      </div>
    </a-form-model>
    <!-- 这里是地图部分 -->
    <div id="elevatorMap"></div>
  </div>
</template>
<script>
import {getLiftinfoById, getLiftinfoListByCondition} from "A/jcgn";
import areaselect from '@/mixins/areaselect'
export default {
  name: 'alarmMap',
  mixins: [areaselect],
  props:{
    MapDataX:{
      default: null
    },
    MapDataY:{
      default: null
    }
  },
  data(){
    return{
      modalVisible:false,
      x_axis:'',
      y_axis:'',
      Map:null,
      fontSize:50,
      queryParams:{
        liftnum:'',
        liftrescue: '',
        adress:'',
        areacode:'',
      },
      queryAreaSelected: [],
      userdepname:'',
      maintenancedepname:'',
      adress:'',
      safephone:'',
      maintenancephone1:'',
      linkiot:'',
      liftid:'',
      deviceinfos:[{terminalmodlenum:'02',devicenum:''}]
    }
  },
  mounted () {
    this.Map = new window.AMap.Map('elevatorMap', {
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom:10, //初始化地图层级
    });
    this.$once('hook:beforeDestroy',()=>{
      this.Map&&this.Map.destroy()
    })
  },
  watch:{
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
  },
  methods: {
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.$refs.queryForm.resetFields();
    },
    getLoaction() {
      let params = {
        ...this.queryParams
      }
      getLiftinfoListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item.length>0){
            this.x_axis = res.item[0].coordinatex
            this.y_axis = res.item[0].coordinatey
            this.liftrescue=res.item[0].liftrescue
            var zoom = Math.floor(Math.random() * 7) + 12;
            var lng = this.x_axis;
            var lat = this.y_axis;
            var marker = new AMap.Marker({
              position: new AMap.LngLat(this.x_axis, this.y_axis),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            this.Map.add(marker);
            this.Map.setZoomAndCenter(zoom, [lng,lat]);
            getLiftinfoListByCondition({liftrescue: this.liftrescue}).then(res => {
              if (res && res.returncode == '0') {
                this.liftid=res.item[0].liftid
                this.deviceinfos[0].devicenum=res.item[0].devicenum
                marker.on('click', (e)=>this.markerClick(e));
              }
            })
          }else{
            this.$message.error("未查询到相应数据")
          }
        }
      })
    },
    markerClick(e){
      getLiftinfoById({
        liftid:this.liftid
      }).then(res=>{
        const {returncode,item} = res
        if(returncode==='0'){
          const info = this.getInfoTemplate(1,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }
      })
    },
    getInfoTemplate(type,item){
      function monitoringClick(type){
        let prePages = [
          {
            name: '基础功能',
            path: ''
          },{
            name: '电子地图',
            path: 'elevatorMap'
          }
        ];
        console.log(item.deviceinfos)
        if(type==='monitoring'){
          if(item.deviceinfos && item.deviceinfos.length) {
            this.$router.push({
              name: 'timeMonitoring',
              params: {
                liftid: item.liftid,
                liftnum: item.liftnum,
                liftcode: item.liftcode,
                deviceinfos: item.deviceinfos,
                prePages: prePages
              }
            })
          }else {
            this.$message.error('该电梯无物联网设备');
          }
        }else if(type==='detail'){
          this.$router.push({
            name: 'elevatorArchivesDetail',
            params: {
              liftid: item.liftid,
              prePages: prePages
            }
          })
        }else{
          this.$message.warning('暂未开发，敬请期待');
        }
      }
      window.operationClick = monitoringClick.bind(this)
      return `<div style="width:250px">
           <div class="map-content">电梯救援码：<span>${item.liftrescue}</span></div>
           <div class="map-content">安装地址：<span>${item.adress}</span></div>
           <div class="map-content">使用单位：<span>${item.userdepname}</span></div>
           <div class="map-content">维保单位：<span>${item.maintenancedepname}</span></div>
           <div class="map-content">维保组：<span>${item.maintenancegroupname}</span></div>
           <div style="margin-top:15px">
           <button type="button" class="ant-btn ant-btn-primary" onclick="operationClick('monitoring')"><span>实时监控</span></button>
              <button type="button" class="ant-btn" onclick="operationClick('detail')"><span>电梯档案详情</span></button>
           </div>
       </div>`
    },
    openInfoWindow(coorArr,info){
      const AMap = window.AMap
      let infoWindow = new AMap.InfoWindow({
        anchor: 'middle-left',
        offset: new AMap.Pixel(3, 0),
        content: info  //使用默认信息窗体框样式，显示信息内容
      });
      this.Map.setZoom(15)
      this.Map.panTo(coorArr)
      infoWindow.open(this.Map, coorArr);
    },
    closeInfoWindow(){
      this.Map.closeInfoWindow()
    },
    location(liftrescue) {
      let params = {
        liftrescue:liftrescue
      }
      getLiftinfoListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item.length>0){
            this.x_axis = res.item[0].coordinatex
            this.y_axis = res.item[0].coordinatey
            this.liftrescue=res.item[0].liftrescue
            var zoom = Math.floor(Math.random() * 7) + 12;
            var lng = this.x_axis;
            var lat = this.y_axis;
            var marker = new AMap.Marker({
              position: new AMap.LngLat(this.x_axis, this.y_axis),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            this.Map.add(marker);
            this.Map.setZoomAndCenter(zoom, [lng,lat]);
            getLiftinfoListByCondition({liftrescue: this.liftrescue}).then(res => {
              if (res && res.returncode == '0') {
                this.liftid=res.item[0].liftid
                this.deviceinfos[0].devicenum=res.item[0].devicenum
                marker.on('click', (e)=>this.markerClick(e));
              }
            })
          }else{
            this.$message.error("未查询到相应数据")
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.find-container{
  height: 730px;
}
#elevatorMap{
  height: calc(100% - 80px);
  margin-top: 5px;
}
</style>